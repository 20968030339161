import { del, get, post, put } from '../../lib/apiHandler';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import React, { useEffect, useState } from 'react'
import Epass from '../../components/charts/Epass';
import { Link } from 'react-router-dom';

import Skeleton from 'react-loading-skeleton'

import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

import { useRecoilState } from 'recoil';
import { popWin0, notiList0, loading, currentUser0 } from '../../lib/states';
import { GoDotFill } from "react-icons/go";

import { Rolleta } from '../../components/Rolleta';
import { ClearLeads } from '../../components/clearLeads';



export default function DeskPage() {
  const router = useNavigate();
  const {deskId} = useParams();
  const {officeId} = useParams();
  const [isLoading, setIsLoading] = useRecoilState(loading)
  const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

  const [deskData, setDeskData] = useState(null)

  const [showEdit, setShowEdit] = useState(false)

  const getDeskData = async () => {
    const result = await get(`/desk/${deskId}`)
    if (result.status == 200) {
      if (result.data.office.id == officeId) {
        setDeskData(result.data)
      } else {
        router('/offices')
      }
    } else {
      router('/offices')
    }
  }



  useEffect(() => {
    getDeskData()
  }, [])

  const [deskName, setDeskName] = useState(deskData != null ? deskData.name : '')
  const [deskExt, setDeskExt] = useState(deskData != null ? deskData.ext : '')

  useEffect(() => {
    if (deskData !== null) {
      setDeskName(deskData.name)
      setDeskExt(deskData.ext)
    }
  }, [deskData])

  const [notiList, setNotiList] = useRecoilState(notiList0)
  const [popWin, setPopWin] = useRecoilState(popWin0)

  const deleteDesk = async () => {
    const newFunc = async () => {
      const result = await del(`/desk/${deskData.id}`)
      if (result.ok) {
        setNotiList(prevValue => [
          ...prevValue,
          {text: result.data.message, isSucc: true} //"Desk successfully deleted"
        ])
        router(`/offices`)
      } else {
        setNotiList(prevValue => [
          ...prevValue,
          {text: result.data.message, isSucc: false} //"Something went wrong"
        ])
      }
    }
    setPopWin({
        disable: true,
        msg: 'Are you sure you want to delete this desk?',
        func: newFunc
      }
      )
  }

  const uploadDesk = async () => {
    setIsLoading(true)
    const result = await put(`/desk/${deskData.id}`, { 
      name: deskName,
      ext: deskExt === '' ? null : deskExt
    })
    if (result.ok) {
      setDeskData(prevValue => {
        return {
          ...prevValue,
          ...result.data.data
        }
      })
      setShowEdit(false)
      setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: true}]) //"The Desk details have been successfully updated"
    } else {
      setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
    }
    setIsLoading(false)
  }

  return (
    <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
      <div className='col-span-2 flex flex-col space-y-4'>
        <div className='flex flex-row justify-between items-center'>
          <span className='text-2xl font-semibold'>
              {deskData != null ? deskData.name : <Skeleton width={200} />}
          </span>
          
          {
            !showEdit ? (
              currentUser.access_level >= 2 && (
                <button className='but h-[36px] w-[36px] p-0 rounded-full' 
                  onClick={() => {
                    setDeskName(deskData.name)
                    setShowEdit(prevValue => !prevValue);
                    //setOfficeInfo(startOfficeInfo)
                  }}
                >
                  <MdModeEdit className='text-[20px]'/>
                </button>
              )
            ) : (
              <div className='space-x-1 flex flex-row'>
                
                <>
                {
                  currentUser.access_level >= 3 && (
                    <button onClick={deleteDesk} className='but-red font-semibold space-x-1'>
                      <MdDelete className='text-[18px]'/>
                      <span className='text-[16px]'>
                        delete
                      </span>
                    </button>
                  )
                }
                  <button onClick={uploadDesk} className='but font-semibold space-x-1'>
                    <FaRegSave className='text-lg'/>
                    <span className='text-[16px]'>
                      Save
                    </span>
                  </button>
                  <button className='but-emp border-none rounded-full bg-gray-50 w-[36px] h-[36px] p-0'
                    onClick={() => {
                      setShowEdit(prevValue => !prevValue);
                      //setOfficeInfo(startOfficeInfo)
                    }}
                  >
                    <IoClose className='text-[18px]' />
                  </button>
                </>
                
              </div>
            )
          }


        </div>

        {
          showEdit && (
            <div className='card p-4 space-y-2'>
              <input className='inp' placeholder="Desk's name" value={deskName} name='desk_name' onChange={(e) => setDeskName(e.target.value)} />
              <input className='inp' placeholder="EXT" value={deskExt} onChange={(e) => setDeskExt(e.target.value)} />
            </div>
          )
        }

        <div className='flex flex-row space-x-4'>

          <div className='card w-1/3 h-[100px] flex flex-col space-y-1 justify-center items-center'>
            <span className="text-md text-gray-800">Leads amount</span>
            {
              deskData != null
              ? <span className="text-xl font-semibold">{deskData.leads_count}</span>
              : <span className='w-20'><Skeleton className='h-5' /></span>
            }
          </div>
          <div className='card w-1/3 h-[100px] flex flex-col space-y-1 justify-center items-center'>
            
            <span className="text-md text-gray-800">Office</span>
            {
              deskData != null
              ? <span className="text-xl font-semibold">{deskData.office.name}</span>
              : <span className='w-20'><Skeleton className='h-5' /></span>
            }
          </div>
          <div className='card w-1/3 h-[100px] flex flex-col space-y-1 justify-center items-center'>
            <span className="text-md text-gray-800">Total transactions</span>
            {
              deskData != null
              ? <span className="text-xl font-semibold">{deskData.total_transaction_sum}</span>
              : <span className='w-20'><Skeleton className='h-5' /></span>
            }
          </div>

        </div>

        <div className='card h-[300px]'>
          <Epass data0={deskData != null ? deskData.lead_status_counts : null} />
        </div>


          {
            currentUser.access_level >= 2 && false && (
              <>

                <div className='flex flex-col space-y-2'>
                  <span className='text-2xl font-semibold'>
                    Assign Leads
                  </span>
                  <Rolleta deskId={deskId} deskData={deskData} />
                </div>
                <div className='flex flex-col space-y-2'>
                  <span className='text-2xl font-semibold'>
                    Reset Lead Assignments
                  </span>
                  <ClearLeads deskId={deskId} allUsers={deskData?.agents} />
                  {/* ...deskData?.agents, ...deskData?.desk_managers */}
                </div>
              </>
            )
          }
      </div>
      <div className='flex flex-col space-y-4'>
          <div className='flex flex-col space-y-2'>
            <span className='text-2xl font-semibold'>
              Desk managers
            </span>
            <div className='card flex flex-col p-2'>
              {
                deskData != null 
                ? deskData.desk_managers.map((agent, i) => (
                  <Link key={i} to={`/users/${agent.id}`} className='hover:bg-gray-50 p-3 flex flex-row items-center space-x-2'>
                    <GoDotFill className='text-orange-600' />
                    <span className='text-lg'>{agent.name}</span>
                    </Link>
                ))
                : [1,2].map(z => <div key={z} className='py-2 px-3'><Skeleton /></div>)
              }
              {
                (deskData != null && deskData.desk_managers.length == 0) && (
                  <ol className='p-2'>No managers</ol>
                )
              }
            </div>

          </div>
          <div className='flex flex-col space-y-2'>
            <span className='text-2xl font-semibold'>
              Desk agents
            </span>
            <div className='card flex flex-col p-2'>
              {
                deskData != null 
                ? deskData.agents.map((agent, i) => (
                  <Link key={i} to={`/users/${agent.id}`} className='hover:bg-gray-50 p-3 flex flex-row items-center space-x-2'>
                    <GoDotFill className='text-orange-600' />
                    <span className='text-lg'>{agent.name}</span>
                    </Link>
                ))
                : [1,2].map(z => <div key={z} className='py-2 px-3'><Skeleton /></div>)
              }
              {
                (deskData != null && deskData.agents.length == 0) && (
                  <ol className='p-2'>No agents</ol>
                )
              }
            </div>
          </div>
      </div>
    </div>
  )
}
