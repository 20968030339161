import objectToQueryParams from './objToParams';
import { get } from './apiHandler';

export const updateOfficeList = async (setOfficesList) => {
    const res = await get(`/offices?${objectToQueryParams({all: true})}`)
    if (res.status == 200) {
      setOfficesList(res.data)
    } else {
      setOfficesList([])
    }
    //setOfficesList(null)
    return true;
}


export const convertValuesToNumber = (obj) => {
  var objNew = {}
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      objNew[key] = Number(obj[key]);
    }
  }
  return objNew;
}

export const formDataToObject = (formData) => {
  let object = {};
  formData.forEach((value, key) => {
      object[key] = value;
  });
  return object;
};
