import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil';
import { loading, notiList0, officesList0, currentUser0 } from '../lib/states';
import { get, post } from '../lib/apiHandler';
import { MdOutlineSaveAlt } from 'react-icons/md'
import { useNavigate } from 'react-router-dom';
import SelectProMulty from './inps/SelectProMulty-new';
import country_two_iso from '../lib/country_two_iso';
import { formDataToObject } from '../lib/funcs';
import SelectCountry from './inps2/SelectCountry';

export default function AddUser2({setCurrentData}) {
    const Router = useNavigate();
    const [isLoading, setIsLoading] = useRecoilState(loading)
    const [notiList, setNotiList] = useRecoilState(notiList0)
    const [officesList, setOfficesList] = useRecoilState(officesList0)
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    
    const [objUser, setObjUser] = useState({
        access_level: '1',
        offices: [],
        desks: []
    })

    const [officeSel, setOfficeSel] = useState([])
    const handleOfficeSel = (obj, isInc) => {
      if (!isInc) {
        setOfficeSel(prevValue => [...prevValue, obj])
      } else {
        setOfficeSel(prevValue => prevValue.filter(prev => prev.id != obj.id))
      }
    }

    useEffect(() => {
      var list = []
      for (var x in officeSel) {
        list.push(...officeSel[x].desks)
      }
      setDesksList(list)
      setDeskSel([])

      setObjUser(prevValue => {
        return {
          ...prevValue,
          offices: officeSel.map(offi => offi.id)
        }
      })
    }, [officeSel])

    // useEffect(() => {
    //     setObjUser(prevValue => {
    //         var list = [];
    //         var list2 = [];
    //         for (var x in officesList) {
    //           if (officeSel.map((offi) => offi.name).includes(officesList[x].name)) {
    //                 list.push(officesList[x].id)
    //                 list2.push(...officesList[x].desks)
    //             }
    //         }
    //         setOfficeDesk(list2)
    //         return {
    //             ...prevValue,
    //             offices: list
    //         }
    //     })
    // }, [officeSel])


    const createUser = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        var formObj = formDataToObject(new FormData(e.target));
        const result = await post('/user', {
          ...objUser,
          countries: choosedCountry.map(country => country.id),
          ...formObj
      })


        if (result.status == 200) {
          await updateUserList()
          setNotiList(prevValue => [
            ...prevValue,
            {text: result.data.message, isSucc: true}
          ])
          await delay(3000);
          Router('/users')
        } else {
          setNotiList(prevValue => [
            ...prevValue,
            {text: result.data.message, isSucc: false}
          ])
        }
        setIsLoading(false)
      }

      const updateUserList = async () => {
        const result = await get('/users')
        setCurrentData(result.data.data)
        return true;
      }

      const handleObjUser = (e, key) => {
        setObjUser(prevValue => {
          return {
            ...prevValue,
            [key]: e.target.value
    
          }
        })
      }

      //// DESK SELECT //////
      const [DesksList, setDesksList] = useState([])
      const [deskSel, setDeskSel] = useState([])

      const handleDeskSel = (obj, isInc) => {
        if (!isInc) {
          setDeskSel(prevValue => [...prevValue, obj])
        } else {
          setDeskSel(prevValue => prevValue.filter(prev => prev.id != obj.id))
        }
      }

      useEffect(() => {
        setObjUser(prevValue => {
          return {
            ...prevValue,
            desks: deskSel.map(des => des.id)
          }
        })
      }, [deskSel])



    ///////////// COUNTRY SELECT ///////////////
    const [choosedCountry, setChoosedCountry] = useState([])

    if (officesList != null)
    return (
    <form className='space-y-5' onSubmit={createUser} autoComplete='off'>
        <div className="z-30 flex items-center justify-between flex-col md:flex-row md:space-y-0 md:space-x-4">
            <div className="flex flex-row space-x-3 w-full">

            </div>
            <div className="flex flex-col items-stretch justify-end flex-shrink-0  space-y-2 w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">

            <div className='flex flex-row justify-end'>
                <button type="button" onClick={() => {Router('/users')}}  className="flex items-center hover:underline justify-center px-4 py-2 text-sm font-medium text-basic w-auto focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    Cancel
                </button>
                <button type="submit" disabled={isLoading} className="space-x-1 text-white bg-basic disabled:bg-orange-400 hover:bg-basic_hov flex flex-row items-center justify-center px-4 py-2 text-sm font-medium rounded-lg focus:ring-4 focus:ring-violet-300 focus:outline-none">
                    <span>
                        Save User
                    </span>
                    <MdOutlineSaveAlt  className='text-lg'/>
                </button>
            </div>
            </div>
        </div>

        <div className='card pb-6 reg-user flex flex-col space-y-4 p-4 justify-between w-full'>
          <div className='text-gray-500 grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='flex flex-col space-y-1 reg-user2'>
                <span className='text-xs'>USER NAME</span>
                <input name='name' className='inp px-4 text-md text-gray-700' autoComplete="off" required />
              </div>
              <div className='flex flex-col space-y-1 reg-user2'>
                <span className='text-xs'>EMAIL</span>
                <input name="email" type='email' className='inp px-4 text-md text-gray-700' autoComplete="off" required />
              </div>
              <div className='flex flex-col space-y-1 reg-user2'>
                <span className='text-xs'>PASSWORD</span>
                <input name="password" className='inp px-4 text-md text-gray-700' type='password' autoComplete="new-password" required />
              </div>

              <div className='flex flex-col space-y-1'>
                <span className='text-xs'>LEVEL</span>
                <select value={objUser.access_level} onChange={(e) => handleObjUser(e, 'access_level')} className='inp px-4 text-md text-gray-700'>
                    {
                      currentUser.access_level == 4 && (
                        <>
                          <option value={4}>Admin</option>
                          <option value={3}>Office Manager</option>
                        </>
                      )
                    }
                    <option value={2}>Desk Manager</option>
                    <option value={1}>Agent</option>
                </select>
              </div>

              <div className='flex flex-col space-y-1'>
                  <span className='text-xs'>OFFICE</span>
                  <SelectProMulty randomId={'create-user-off'} optionsData={[...officesList]} choosedOption={officeSel} setChoosedOption={handleOfficeSel} styleBut='w-full h-[46px]' />
              </div>

              <div className='flex flex-col space-y-1'>
                <span className='text-xs'>DESK</span>
                <SelectProMulty randomId={'create-user-desk'} optionsData={[...DesksList]} choosedOption={deskSel} setChoosedOption={handleDeskSel} styleBut='w-full h-[46px]' />
                {/* <DropList2 id={'3'} ths={deskSel} setThs={setDeskSel} allThs={officeDesk.map((item) => ({...item, display: item.name}))} title={titleSelectDesk}  styleBut={'w-full h-[46px] bg-gray-50 text-gray-800 border-gray-300 hover:text-gray-800 hover:bg-gray-50 shadow'} /> */}
              </div>


              <div className='flex flex-col space-y-1'>
                <span className='text-xs'>COUNTRIES</span>
                <SelectCountry choosedCountry={choosedCountry} setChoosedCountry={setChoosedCountry} />
              </div>

              

              <div className='flex flex-col space-y-1'>
                <span className='text-xs'>Leads Allocation</span>
                <input className='inp' name='leads_allocation' defaultValue={0} pattern="[0-9]*" required  />
              </div>

              <div className='flex flex-col space-y-1'>
                <span className='text-xs'>extension</span>
                <input className='inp' name='extension' pattern="[0-9]*" />
              </div>


          </div>
        </div>
    </form>
  )
}

const delay = ms => new Promise(res => setTimeout(res, ms));