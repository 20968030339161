import React, { useState, useEffect } from 'react'
import { GrEdit } from 'react-icons/gr';
import { HiDotsHorizontal } from 'react-icons/hi';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useRecoilState } from 'recoil';
import { currentUser0, loading, notiList0, popWin0 } from '../../lib/states';
import { Link } from 'react-router-dom';
import { post, put, del } from '../../lib/apiHandler';
import objectToQueryParams from '../../lib/objToParams';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



export default function Tr({ths, dat, getTransactionsData, i, clientId}) {
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const [editMode, setEditMode] = useState(false)
    const [newDat, setNewDat] = useState({
        amount: dat.amount,
        date: dat.date
    })

  return (
    <tr id={`drop-trans-${i}`} className="even:bg-gray-50 odd:bg-white dark:bg-gray-800 dark:border-gray-700">
        {
            ths.map((title, z) => (
                <Td key={z} title={title} dat={dat} editMode={editMode} newDat={newDat} setNewDat={setNewDat} />                 
            ))
        }
        {
            currentUser.access_level >= 1 && (
                <DropEdit i={i} getTransactionsData={getTransactionsData} leadId={clientId != null ? clientId : currentUser.id} transactia={dat} editMode={editMode} setEditMode={setEditMode} newDat={newDat} />
            )
        }
    </tr>
  )
}


export  function DropEdit({transactia, setEditMode, editMode, newDat, leadId, getTransactionsData, i}) {
    const [isLoading, setIsLoading] = useRecoilState(loading)
    const [dropEdit, setDropEdit] = useState(false)
    const [notiList, setNotiList] = useRecoilState(notiList0)
    const [popWin, setPopWin] = useRecoilState(popWin0)

    const handleUpdate = async () => {
        setIsLoading(true)
        const result = await put(`/lead/${leadId}/transaction/${transactia.id}`, objectToQueryParams(newDat))
        if (result.ok) {
            setNotiList(prevValue => [...prevValue, {text: 'Transaction updated successfully', isSucc: true}])
            setEditMode(false)
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
        }
        await getTransactionsData()
    }

    const handleDel = async () => {

        const newFunc = async () => {
            const result = await del(`/lead/${leadId}/transaction/${transactia.id}`)
            setDropEdit(false)
            if (result.status == 200) {
                setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: true}]) //"Transaction successfully removed"
            } else {
                setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}]) //"Something went wrong"
            }
            await getTransactionsData()
        }

        setPopWin({
            disable: true,
            msg: 'Are you sure you want to delete this Transaction?',
            func: newFunc
        })



    }

  return (
    <td className='relative'>
        {
            editMode
            ? (
                <div className='flex flex-row [&>button]:w-[55px] [&>button]:py-1 space-x-1'>
                    <button onClick={() => setEditMode(false)} className='but-emp'>Cancel</button>
                    <button onClick={handleUpdate} className='but'>Save</button>
                </div>
            ) : (
                <div className='flex justify-end'>
                    <div onClick={() => setDropEdit(prevValue => !prevValue)} className=' hoverEffect w-[40px] h-[40px] flex justify-center items-center'>
                        <HiDotsHorizontal />
                    </div>
                    {
                        dropEdit && (
                            <DropUl i={i} setEditMode={setEditMode} setDropEdit={setDropEdit} handleDel={handleDel} />
                        )
                    }
                </div>
            )
        }
    </td>
  )
}


export function DropUl({setEditMode, setDropEdit, handleDel, i}) {

    useEffect(() => {
        const handleBodyClick = (e) => {
            var element = e.target
            var isInside = false;
            while (true) {
                if (`drop-trans-${i}` == element.id) {
                    isInside = true
                    break
                }
                element = element.parentElement
                if (element == null) {
                    break
                }
            }
            if (!isInside) {
                setDropEdit(false)
            }
        };
        document.body.addEventListener('click', handleBodyClick);
        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, [])
  return (
    <div className='absolute card  right-11 z-20 '>
        <ul className='text-[16px] text-gray-800 cursor-pointer'>
            <li onClick={() => {setEditMode(true); setDropEdit(false)}} className='hover:bg-gray-50 p-3 flex flex-row items-center space-x-3 active:bg-gray-100'>
                <GrEdit className='text-[20px]' />
                <span>
                    Edit
                </span>
            </li>
            <li onClick={() => {handleDel();}} className='hover:bg-gray-50 hover:text-red-600 p-3 flex flex-row items-center space-x-3 active:bg-gray-100'>
                <RiDeleteBin5Line className='text-[20px]' />
                <span>
                    Delete
                </span>
            </li>
        </ul>
    </div>
  )
}



export function Td({dat, title, editMode, newDat, setNewDat}) {
    
  return (
    <td className="px-4 py-4">
        {
            title == 'user' && (
                <Link className='hover:underline' to={`/users/${dat[title].id}`}>{dat[title].name}</Link>
            )
        }
        {
            (title != 'user' && title != 'edit') && (
                editMode
                ? (
                    title == 'date' ? (
                        <DatePicker
                        className='inp z-20'
                        selected={new Date(newDat[title])}
                        onChange={(date) => setNewDat(prevValue => {return {...prevValue, [title]: formatDate(date)}})}
                        dateFormat="yyyy-MM-dd"
                        />
                    ) : (
                        <input className='inp' value={newDat[title]} onChange={(e) => setNewDat(prevValue=> {return {...prevValue, [title]: e.target.value}})} />
                    )
                )
                : (
                <span>{dat[title]}</span>
                )
            )
        }
    </td> 
  )
}


function formatDate(date) {
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
}