import { BrowserRouter as Router, Routes, Route, Navigate  } from 'react-router-dom';
import { AuthProvider, useAuth } from './lib/AuthContext';

import Index from './pages/general/index'
import NotFound from './pages/general/404';
import Login from './pages/general/login';

import Import from './pages/clients/import';
import ClientPage from './pages/clients/client-page';
import Clients from './pages/clients/Clients';

import Users from './pages/users/users';
import AddUser from './pages/users/add-user';
import UserPage from './pages/users/user-page';

import Teams from './pages/offices/office';
import OfficePage from './pages/offices/office-page';
import DeskPage from './pages/offices/desk-page';
import Layout from './pages/general/Layout';


function App() {
  return (
    <AuthProvider>
      <Router>
          <Routes>
            <Route path="/" 
              element={<Navigate to="/clients" />}
            />

            <Route path="/login" 
              element={<AuthRoute restricted={true} component={<Login />} />} 
            />

            <Route path="/clients" 
              element={<PrivateRoute component={<Clients />} />}
            />
            <Route path="/clients/import" 
              element={<PrivateRoute component={<Import />} />}
            />
            <Route path="/clients/:clientId" 
              element={<PrivateRoute component={<ClientPage />} />}
            />


            <Route path="/users" 
              element={<PrivateRoute component={<Users />} />}
            />
            <Route path="/users/add" 
              element={<PrivateRoute component={<AddUser />} />}
            />
            <Route path="/users/:userId" 
              element={<PrivateRoute component={<UserPage />} />}
            />


            <Route path="/offices" 
              element={<PrivateRoute component={<Teams />} />}
            />
            <Route path="/offices/:officeId" 
              element={<PrivateRoute component={<OfficePage />} />}
            />
            <Route path="/offices/:officeId/:deskId" 
              element={<PrivateRoute component={<DeskPage />} />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
    </AuthProvider>
  );
}

const PrivateRoute = ({ component }) => {
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? (
    <Layout>
      {component}
    </Layout>
  ) : (
    <Navigate to="/login" replace state={{ from: window.location.pathname }} />
  );
};

const AuthRoute = ({ component, restricted }) => {
  const { isLoggedIn } = useAuth();

  return isLoggedIn && restricted ? (
    <Navigate to="/" />
  ) : (
    component
  );
};


export default App;
