import React, { useState, useEffect } from 'react'
import { useRef } from 'react';

export default function DropList2({ths, setThs, allThs, title, styleBut, id}) {
    const [droped, setDroped] = useState(false)
    const myElementRef = useRef();
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [maxH, setMaxH] = useState(250)

    useEffect(() => {
        const elementTop = myElementRef.current.offsetTop;
        setMaxH(windowHeight - elementTop)
    }, [windowHeight])

    useEffect(() => {
        const handleResize = () => {
          setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleChange = (e, th) => {
        setThs(prevValue => {
            if (!e.target.checked) {
                
                return [
                    ...prevValue.filter((title2) => title2.display != th.display)
                ]
            } else {
                return [
                    ...prevValue,
                    th
                ]
            }
        })
    }


    useEffect(() => {
        const handleBodyClick = (e) => {
            var element = e.target
            var isInside = false;
            while (true) {
                if (`drop-col-${id}` == element.id) {
                    isInside = true
                }
                element = element.parentElement
                if (element == null) {
                    break
                }
            }
            if (!isInside) {
                setDroped(false)
            }
        };
        document.body.addEventListener('click', handleBodyClick);
        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, [droped])

  
    return (
    <div id={`drop-col-${id}`}>
        <button
        ref={myElementRef}
        className={`but-emp hover:bg-basic_hov flex flex-row justify-between ${styleBut}`}
        type="button"
        onClick={() => setDroped(prevValue => !prevValue)}
        >
            <span className='truncate'>{title}</span>
            <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/></svg>
        </button>
        {
            droped && (
                <div className='relative top-1'>
                    <div style={{
                        maxHeight: `${maxH - 60}px`
                    }} className={`z-10 fixed overflow-y-auto bg-white overflow-hidden divide-y divide-gray-100 rounded-lg shadow w-44`}>
                        <ul className="text-sm text-gray-700" >
                            {
                                allThs.map((th, i) => {
                                    return (
                                        <div key={i} className="flex items-center rounded hover:bg-gray-100  pl-2">
                                            <input checked={ths.map((tz) => tz.display).includes(th.display)} onChange={(e) => handleChange(e, th, i,)} id={`checkbox-col-${i}`} type="checkbox" value="" 
                                            className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                            <label htmlFor={`checkbox-col-${i}`} className="p-2 cursor-pointer w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
                                                {th.display}
                                            </label>
                                        </div>
                                    
                                )})
                            }
                        </ul>
                    </div>

                </div>
            )
        }
    </div>
  )
}
