import { put } from "../../lib/apiHandler"
import langIsoToName from "../../lib/langIsoToName"
import { useRecoilState } from 'recoil';
import { notiList0 } from '../../lib/states';
import { useEffect } from "react";


const SelectLang = ({clientId, defaultLang}) => {
    // useEffect(() => {
    //     if (defaultLang !== null) {
    //         console.log(clientId, defaultLang)
    //     }
    // }, [])
    const [notiList, setNotiList] = useRecoilState(notiList0)

    const updateLang = async (e) => {
        const result = await put(`/lead/${clientId}`, {
            lang: e.target.value
        })
        if (result.ok) {
            setNotiList(prevValue => [
                ...prevValue,
                {text: "Lead's language successfully updated", isSucc: true}
            ])
        } else {
            setNotiList(prevValue => [
                ...prevValue,
                {text: result.data.message, isSucc: false}
            ])
        }
    }


    return (
        <select 
            className='w-full shadow-md py-1 w-full text-gray-900 border border-gray-300 rounded-lg !bg-white' 
            defaultValue={defaultLang} 
            onChange={updateLang}
        >
            {
                <>
                    <option value={''}></option>
                {
                    Object.keys(langIsoToName).map((isoCountry, i )=> (
                        <option key={i} value={isoCountry}>
                            {langIsoToName[isoCountry]}
                        </option>
                    ))
                }
                </>
            }
        </select>
    )
}

export default SelectLang