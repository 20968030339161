import React, { useEffect, useState } from 'react'
import { BiFilterAlt } from 'react-icons/bi';
import { HiFilter} from 'react-icons/hi'
import {  officesList0, desksList0, usersList0, toForce0 } from '../../lib/states';
import { useRecoilState, useSetRecoilState } from 'recoil';
import SelectProMulty from '../inps/SelectProMulty-new';
import country_two_iso from '../../lib/country_two_iso';
import isoToCountry from '../../lib/isoToCountry';
import SelectLangs from '../inps2/SelectLangs';
import langIsoToName from '../../lib/langIsoToName';

export default function ThFilter({index, setObjFilter, title, objFilter, choosedAfterRem, setChoosedAfterRem}) {
    const [toForce, SetToForce] = useRecoilState(toForce0)

    const [drop, setDrop] = useState(false)
    var filterList = JSON.parse(objFilter.filter);
    if (filterList[title.key] == null) {
        filterList = {
            ...filterList,
            [title.key]: []
        }
    }

    const dropId = `drop-${index}`
    useEffect(() => {
        const handleBodyClick = (e) => {
            var element = e.target
            var isInside = false;
            var isButFunc = false
            while (true) {
                if (element.id.slice(0, 5) == 'thKey'){
                    if (element.id.split('-')[1] == index) {
                        isButFunc = true
                    }
                }
                if (dropId == element.id) {
                    isInside = true
                }
                element = element.parentElement
                if (element == null) {
                    break
                }
            }
            
            if (!isInside && !isButFunc) {
                setDrop(false)
            }
        };
        document.body.addEventListener('click', handleBodyClick);
        return () => {
        document.body.removeEventListener('click', handleBodyClick);
        };
    }, [drop])

    useEffect(() => {
        if (drop === false) {
            SetToForce(prevValue => !prevValue)
        }
    }, [drop])

    const isClickIn = (e) => {
        var element = e.target;
        var isInside = false

        while (true) {
            if (element.getAttribute("name") == 'box') {
                isInside = true
            }
            element = element.parentElement
            if (element == null) {
                break
            }
        }
        if (!isInside) {
            setDrop(prevValue => !prevValue)
        }
    }

    ////////////// filter with droplist ///////////
    
    const handleChooseOffices = async (officeObj, isInc, titleKey) => { ////////////////////////////////////// make a delay delay !!!! ///////////////
        const updateFunc = (listData) => {
            if (titleKey == 'office_name')  return setChoosedOffices(listData)
            if (titleKey == 'desk_name')  return setChoosedDesks(listData)
            if (titleKey == 'user_name')  return setChoosedUsers(listData)
            if (titleKey == 'status')  return setChoosedStatus(listData)
            if (titleKey == 'access_level')  return setChoosedLevel(listData)
            if (titleKey == 'country')  return setChoosedCountry(listData)
            if (titleKey == 'lang')  return setChoosedLang(listData)
        }

        !isInc 
        ? updateFunc(prevValue => [officeObj, ...prevValue])
        : updateFunc(prevValue => prevValue.filter(prev => prev.id != officeObj.id))

        if (isInc) { /// un
            setObjFilter(prevValue => {
                var prevFilter = JSON.parse(prevValue.filter);
                if (prevFilter[titleKey] != null) {
                    var newFilter = prevFilter[titleKey].filter(prev => prev.value != ((titleKey != 'access_level' && titleKey != 'country' && titleKey != 'lang') ? officeObj.name : officeObj.id))

                    if (officeObj.name === 'null') {
                        newFilter = prevFilter[titleKey].filter(prev => prev.value !== null)
                    }

                    if (newFilter.length == 0) {
                        delete prevFilter[titleKey];
                    } else {
                        prevFilter[titleKey] = newFilter
                    }
                    prevValue.filter = JSON.stringify(prevFilter)
                }
                return {
                    ...prevValue,
                }
            })
        } else { // simun
            setObjFilter(prevValue => {
                var prevFilter = JSON.parse(prevValue.filter);

                if (prevFilter[title.key] == null) {
                    prevFilter[title.key] = []
                }
                if (officeObj.name === 'null') {
                    prevFilter[titleKey].push({
                        "operator":"is","value": null, "join":"or"
                    })
                } else {
                    prevFilter[titleKey].push({
                        "operator":"eq","value": (titleKey != 'access_level' && titleKey != 'country' && titleKey != 'lang') ? officeObj.name : officeObj.id, "join":"or"
                    })
                }
                return {
                    ...prevValue,
                    filter: JSON.stringify(prevFilter)
                }
            })
        }
    }
    // offices
    const getChoosedOffi = (keyTitle) => {
        var list = [];
        const fil_off = JSON.parse(objFilter.filter)[keyTitle]
        if (fil_off != null) {
            if (keyTitle == 'office_name') {
                for (var x in fil_off) {
                    list.push(officesList.find((offi) => offi.name == fil_off[x].value))
                }
            }
            if (keyTitle == 'desk_name') {
                for (var x in fil_off) {
                    list.push(desksList.find((offi) => offi.name == fil_off[x].value))
                }
            }
            if (keyTitle == 'user_name') {
                for (var x in fil_off) {
                    list.push(usersList.find((offi) => offi.name == fil_off[x].value))
                } 
            }
            if (keyTitle == 'status') {
                for (var x in fil_off) {
                    list.push({name: fil_off[x].value, id: fil_off[x].value})
                }
            }   
            if (keyTitle == 'access_level') {
                for (var x in fil_off) {
                    list.push(levelList[Number(fil_off[x].value) - 1])
                }
            }
            if (keyTitle == 'country') {
                for (var x in fil_off) {
                    list.push({name: isoToCountry[fil_off[x].value], id: fil_off[x].value})
                }
            }
            if (keyTitle == 'language') {
                for (var x in fil_off) {
                    list.push({name: langIsoToName[fil_off[x].value], id: fil_off[x].value})
                }
            }
        }
        return(list)
    } 
//////////////////////////////

useEffect(() => {
    if (typeof JSON.parse(objFilter.filter)['office_name'] === 'undefined' && choosedOffices.length !== 0) {
        setChoosedOffices([])
    }
    if (typeof JSON.parse(objFilter.filter)['desk_name'] === 'undefined' && choosedDesks.length !== 0) {
        setChoosedDesks([])
    }
    if (typeof JSON.parse(objFilter.filter)['user_name'] === 'undefined' && choosedUsers.length !== 0) {
        setChoosedUsers([])
    }
}, [objFilter])



/////////////////////////////////////

    
    const [officesList, setOfficesList] = useRecoilState(officesList0)
    const [choosedOffices, setChoosedOffices] = useState(getChoosedOffi('office_name'))
    // desks
    const [desksList, setDesksList] = useRecoilState(desksList0)
    const [choosedDesks, setChoosedDesks] = useState(getChoosedOffi('desk_name'))
    // users
    const [usersList, setUsersList] = useRecoilState(usersList0)
    const [choosedUsers, setChoosedUsers] = useState(getChoosedOffi('user_name'))
    //status
    const [statusList, setStatusList] = useState([{name: 'New', id: 'New'}, {name: 'No answer', id: 'No answer'}, {name: 'Call again', id: 'Call again'}, {name: 'Not Interested', id: 'Not Interested'}, {name: 'Wrong Details', id: 'Wrong Details'}, {name: 'In progress', id: 'In progress'}, {name: 'Meeting', id: 'Meeting'},  {name: 'Deposit', id: 'Deposit'}])
    const [choosedStatus, setChoosedStatus] = useState(getChoosedOffi('status'))

    // access_level
    const [levelList, setLevelList] = useState([{name: 'Agent', id: '1'}, {name: 'Desk manager', id: '2'}, {name: 'Office manager', id: '3'}, {name: 'Admin', id: '4'}])
    const [choosedLevel, setChoosedLevel] = useState(getChoosedOffi('access_level'))

    // country
    const [choosedCountry, setChoosedCountry] = useState(getChoosedOffi('country'))


    // language
    const [choosedLang, setChoosedLang] = useState(getChoosedOffi('lang'))





    useEffect(() => {
        if (choosedAfterRem != '' && choosedAfterRem != null) {
            if (choosedAfterRem == 'office_name') {
                setChoosedOffices(getChoosedOffi(choosedAfterRem))
            } else if (choosedAfterRem == 'desk_name') {
                setChoosedDesks(getChoosedOffi('desk_name'))
            } else if (choosedAfterRem == 'user_name') {
                setChoosedUsers(getChoosedOffi('user_name'))
            } else if (choosedAfterRem == 'status') {
                setChoosedStatus(getChoosedOffi('status'))
            } else if (choosedAfterRem == 'access_level') {
                setChoosedLevel(getChoosedOffi('access_level'))
            } else if (choosedAfterRem == 'country') {
                setChoosedCountry(getChoosedOffi('country'))
            } else if (choosedAfterRem == 'language') {
                setChoosedLang(getChoosedOffi('language'))
            }
            setChoosedAfterRem('')
        }
    }, [choosedAfterRem])



  return (
    <div id={`thKey-${index}`} onClick={isClickIn}>
        {
            filterList[title.key].length == 0
            ?   <BiFilterAlt className='text-lg' />
            :   <HiFilter className='text-lg' />
        }
        {
            drop && (
                <div className='absolute p-2 bg-gray-50 font-medium space-y-2 top-[55px] card w-[180px] cursor-default' id={dropId} name="box">
                    {   
                        title.key == 'office_name' && (
                            <div>
                                <SelectProMulty toNothing={true} randomId={`th-${title.key}`} optionsData={officesList} choosedOption={choosedOffices} setChoosedOption={handleChooseOffices} titleKey={title.key} />
                            </div>
                        )
                    }
                    {   
                        title.key == 'desk_name' && (
                            <div>
                                 <SelectProMulty toNothing={true} randomId={`th-${title.key}`} optionsData={desksList} choosedOption={choosedDesks} setChoosedOption={handleChooseOffices} titleKey={title.key} />
                            </div>
                        )
                    }
                    {   
                        title.key == 'user_name' && (
                            <div>
                                 <SelectProMulty toNothing={true} randomId={`th-${title.key}`} optionsData={usersList} choosedOption={choosedUsers} setChoosedOption={handleChooseOffices} titleKey={title.key} />
                            </div>
                        )
                    }
                    {   
                        title.key == 'status' && (
                            <div>
                                 <SelectProMulty randomId={`th-${title.key}`} optionsData={statusList} choosedOption={choosedStatus} setChoosedOption={handleChooseOffices} titleKey={title.key} />
                            </div>
                        )
                    }

                    {   
                        title.key == 'access_level' && (
                            <div>
                                 <SelectProMulty randomId={`th-${title.key}`} optionsData={levelList} choosedOption={choosedLevel} setChoosedOption={handleChooseOffices} titleKey={title.key} />
                            </div>
                        )
                    }

                    {   
                        title.key == 'country' && (
                            <div>
                                 <SelectProMulty toNothing={true} randomId={`th-${title.key}`} optionsData={country_two_iso} choosedOption={choosedCountry} setChoosedOption={handleChooseOffices} titleKey={title.key} />
                            </div>
                        )
                    }

                    {   
                        title.key == 'lang' && (
                            <div>
                                <SelectLangs choosedLang={choosedLang} setChoosedLang={setChoosedLang} setChoosedOption={handleChooseOffices} titleKey={title.key} />
                            </div>
                        )
                    }




                    {
                        (title.key != 'office_name' && title.key != 'desk_name' && title.key != 'user_name' && title.key != 'status' && title.key != 'access_level' && title.key != 'country' && title.key != 'lang') && (
                            [...filterList[title.key], {join: "and", operator: "contains", value: "", zero: true}].map((item, i) => (
                                <div key={i} className=''>
                                    {/* {i} */}
                                    <Block setObjFilter={setObjFilter} title={title} index={i} data={item} />
                                </div>
                            ))
                        )
                    }
                </div>
            )
        }
    </div>
  )
}


function Block({setObjFilter, title, index, data}) {
    const [text, setText] = useState(data != null ? data.value : '')
    const [allRadio, setAllRadio] = useState(data.join == 'and' ? true : false);
    const [oper, setOper] = useState('contains')
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (text != '') {
                setObjFilter(prevValue => {
                    var prevFilter = JSON.parse(prevValue.filter);
                    if (prevFilter[title.key] == null) {
                        prevFilter[title.key] = []
                    }
                    prevFilter[title.key][index] = {
                        "operator": oper,
                        "value": text,
                        "join": allRadio ? "and" : "or"
                    }
                    return {
                        ...prevValue,
                        filter: JSON.stringify({
                            ...prevFilter,
                            [title.key]: [
                                ...prevFilter[title.key],
                            ]
                        })
                    }
                })
            } else {
                if (data.zero != true) {
                    setObjFilter(prevValue => {
                        var prevFilter = JSON.parse(prevValue.filter);
                        if (prevFilter[title.key] == null) {
                            prevFilter[title.key] = []
                        }
                        var prevList = prevFilter[title.key];
                        prevList.splice(index, 1);
                        delete prevFilter[title.key];
                        if (prevList.length != 0) {
                            return {
                                    ...prevValue,
                                    filter: JSON.stringify({
                                        ...prevFilter,
                                        [title.key]: [...prevList]
                                })
                            }
                        } else {
                            return {
                                ...prevValue,
                                filter: JSON.stringify({
                                    ...prevFilter,
                                })
                            }
                        }
                    })
                }
            }
          
        }, 100);
    
        return () => {
          clearTimeout(timer);
        };
    }, [text, oper, allRadio]);
    
      const handleInputChange = (event) => {
        setText(event.target.value);
    };

    return (
        <div className='space-y-2'>
            <select value={oper} onChange={(e) => setOper(e.target.value)} className='inp bg-white text-gray-700'>
                <option value="contains">Contains</option>
                <option value="ncontains">Not Contains</option>
                <option value="eq">Equals</option>
                <option value="neq">Not Equals</option>
                <option value="gt">Greather than</option>
                <option value="lt">Lower than</option>
            </select>
            <input value={text} onChange={handleInputChange} className='inp text bg-white text-gray-700' placeholder='Filter...' />
            {
                text != '' && (
                <div className='flex flex-row justify-evenly py-1'>
                    <label className='space-x-1'>
                        <input
                        type="radio"
                        value="and"
                        checked={allRadio}
                        onChange={(e) => setAllRadio(e.target.value)}
                        />
                        <span>AND</span>
                    </label>
                    <label className='space-x-1'>
                        <input
                        type="radio"
                        value="or"
                        checked={!allRadio}
                        onChange={(e) => setAllRadio(!e.target.value)}
                        />
                        <span>OR</span>
                    </label>
                </div>
                )
            }
        </div>
    )

}