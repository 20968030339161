import React, { useEffect, useState } from 'react'
import { get, post } from '../../lib/apiHandler';
import { useRecoilState } from 'recoil';
import { loading, popWin0, notiList0, currentUser0, toForce0 } from '../../lib/states';
import { AiOutlinePlus } from 'react-icons/ai';
import { RiDeleteBin5Line } from 'react-icons/ri';

import Table from '../../components/tables/Table-new';


import objectToQueryParams from '../../lib/objToParams';
import { useNavigate } from 'react-router-dom';
import BubbleFilters from '../../components/BubbleFilters';

export default function Users() {
  const [toForce, SetToForce] = useRecoilState(toForce0)

  const [currentData, setCurrentData] = useState(null)
  const [choosedAfterRem, setChoosedAfterRem] = useState('')
  const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
  const [isLoading, setIsLoading] = useRecoilState(loading)

  const allThs = [
    {key: 'id', display: 'id'},
    {key: 'name', display: 'name'},
    {key: 'email', display: 'email'},
    {key: 'extension', display: 'extension'},
    {key: 'access_level', display: 'access_level'},
    {key: 'office_name', display: 'offices'},
    {key: 'desk_name', display: 'desks'},
    {key: 'leads_count', display: 'leads count'},
  ]

  

  const [ths, setThs] = useState([...allThs])
  
  const [uploadForce, setUploadForce] = useState(false)
  const [notiList, setNotiList] = useRecoilState(notiList0)
  
  const usersFilter = JSON.parse(localStorage.getItem('usersFilter'))
  const [objFilter, setObjFilter] = useState(
    usersFilter != null
    ? usersFilter
    : {
      sort_by: 'id',
      sort_direction: 'desc',
      page: 1,
      per_page: 10,
      filter: JSON.stringify({})
    }
  )
  
  const func = async () => {
    localStorage.setItem('usersFilter', JSON.stringify(objFilter))
    setCurrentData(null)
    const paramStr = objectToQueryParams(objFilter)
    const result = await get(`/users?${paramStr}`)
    setCurrentData(result.data)
  }


  useEffect(() => {
    var isChange = false;


    var tempFill = localStorage.getItem('usersFilter')
    if (tempFill === null) {
      tempFill = {}
    } else {
      tempFill =  JSON.parse(tempFill)
    }
    const oldObjFilter = tempFill


    for (var k of Object.keys(objFilter)) {
      if (objFilter[k] !== oldObjFilter[k]) {
        isChange = true
      }
    }

    if (isChange) {
      func()
    }
  }, [toForce])

    useEffect(() => {
      func()
    }, [uploadForce])



  // useEffect(() => {
  //   const func = async () => {
  //     localStorage.setItem('usersFilter', JSON.stringify(objFilter))
  //     setCurrentData(null)
  //     const paramStr = objectToQueryParams(objFilter)
  //     const result = await get(`/users?${paramStr}`)
  //     setCurrentData(result.data)
  //   }
  //   func();
  // }, [objFilter, uploadForce])

  const Router = useNavigate();

  const [listCheked, setListCheked] = useState([])



  const [popWin, setPopWin] = useRecoilState(popWin0)
  const handleRemoveUsers = async () => {
      setIsLoading(true)
      const newFunc = async () => {
          var leadsToRemove = []
          for (var x in listCheked) {
              leadsToRemove.push(currentData.data[listCheked[x]].id)
          }
          const result = await post('/users/delete', {
              ids: leadsToRemove
          })
          if (result.status == 200) {
              setNotiList(prevValue => [
                ...prevValue,
                {text: result.data.message, isSucc: true} //'Leads successfully deleted'
              ])
              setUploadForce(prevValue => !prevValue)
            } else {
              setNotiList(prevValue => [
                ...prevValue,
                {text: result.data.message, isSucc: false}
              ])
          }
      }
      setPopWin({
          disable: true,
          msg: 'Are you sure you want to delete the marked Users?',
          func: newFunc
      })
      setIsLoading(false)
  }




  return (
    <div className='flex flex-col space-y-2 sm:space-y-5'>
          <>
            <div className="z-30 flex items-center justify-between flex-row space-x-4">
                <BubbleFilters allThs={allThs} choosedAfterRem={choosedAfterRem} setChoosedAfterRem={setChoosedAfterRem} objFilter={objFilter} setObjFilter={setObjFilter}/>
                {
                  currentUser.access_level >= 3 && listCheked.length > 0 && (
                    <button className='but-red  px-2' onClick={handleRemoveUsers}>
                      <RiDeleteBin5Line className='text-lg' />
                    </button>
                  )
                }
                
                
                <div className="flex flex-col items-stretch justify-end flex-shrink-0  space-y-2 w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                    <button type="button" onClick={() => Router('/users/add')} className="space-x-1 text-white bg-basic hover:bg-basic_hov flex flex-row items-center justify-center px-4 py-2 text-sm font-medium rounded-lg focus:ring-4 focus:ring-violet-300 focus:outline-none">
                        <span>
                            Add user
                        </span>
                        <AiOutlinePlus />
                    </button>
                </div>
            </div>
          
            <Table 
              currentData={currentData} setCurrentData={setCurrentData}
              allThs={allThs}
              ths={ths}
              objFilter={objFilter} setObjFilter={setObjFilter}
              noTotal={true}
              setUploadForce={setUploadForce}
              choosedAfterRem={choosedAfterRem} setChoosedAfterRem={setChoosedAfterRem}
              listCheked={listCheked} setListCheked={setListCheked}
            />
          </>


    </div>
  );
}
