import React, { useState } from 'react'
import AddUser2 from '../../components/AddUser2';
import { useRecoilState } from 'recoil';
import { loading } from '../../lib/states';



export default function AddUser() {
    const [text, setText] = useState('')
    const [isLoading, setIsLoading] = useRecoilState(loading)
    const [currentData, setCurrentData] = useState(null)
    const allThs = ['name', 'email', 'access_level', 'offices', 'desks']
    const [ths, setThs] = useState(['name', 'email', 'access_level', 'offices', 'desks'])

  return (
        <AddUser2
        setCurrentData={setCurrentData}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        />
  )
}
