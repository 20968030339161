

export default function objectToQueryParams(obj) {
    const queryParams = new URLSearchParams();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        queryParams.append(key, obj[key]);
      }
    }
    return queryParams.toString();
}