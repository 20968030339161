import React from 'react'
import { useRecoilState } from 'recoil';
import { toForce0 } from '../lib/states';

export default function BubbleFilters({objFilter, setObjFilter, setChoosedAfterRem, allThs}) {
    const [toForce, SetToForce] = useRecoilState(toForce0)

    const filter = JSON.parse(objFilter.filter)

    const filterKeys = Object.keys(filter).map(title => 
        allThs.find(th => th.key == title).display
    )

    const handleRem = (title) => {
        const title0 = allThs.find(th => th.display == title).key
        setObjFilter(prevValue => {
            var new_filter = JSON.parse(prevValue.filter)
            delete new_filter[title0];
            return {
                ...prevValue,
                filter: JSON.stringify({
                    ...new_filter
                })
            }
        })
        setChoosedAfterRem(title)
        SetToForce(prev => !prev)
    }
  return (
    <div className="flex flex-row space-x-1 w-full">
        {
            filterKeys.map((title, i) => (
                <span key={i} className="buble">
                    {title}
                    <button onClick={() => handleRem(title)} type="button" className="inline-flex items-center p-1 ml-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                        <svg className="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                    </button>
                </span>
            ))
        }
    </div>
  )
}
