import React, { useState } from 'react'
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai';
import { useRecoilState } from 'recoil';
import { toForce0 } from '../../lib/states';

export default function ThSort({setObjFilter, item}) {
    const [toForce, SetToForce] = useRecoilState(toForce0)
    const [dir, setDir] = useState(false);

    const sortBy = () => {
        setObjFilter(prevValue => {
            return {
                ...prevValue,
                sort_by: item,
                sort_direction: dir ? 'asc' : 'desc',
            }
        })
        setDir(prevValue => !prevValue)
        SetToForce(prev => !prev)
    }

  return (
    <div className=''>
        {
            dir 
            ? <AiOutlineSortDescending onClick={() => sortBy()} className='text-xl' />
            : <AiOutlineSortAscending onClick={() => sortBy()} className='text-xl' />
        }
    </div>
  )
}
