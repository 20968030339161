
function NotFound() {
    return (
      <div>
        404 page
      </div>
    );
  }
  
  export default NotFound;
  