import React, { useEffect, useState, useRef } from 'react'

import { FiCalendar } from 'react-icons/fi';
import { AiOutlinePlus } from 'react-icons/ai';
import { PiNoteLight } from 'react-icons/pi';
import { del, get, post } from '../lib/apiHandler';

import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'

import { useRecoilState } from 'recoil';
import { loading, notiList0, popWin0} from '../lib/states';

import { RiDeleteBin5Line } from 'react-icons/ri';


export default function NoteSection({ids}) {
    const [addNote, setAddNote] = useState(false)
    const [msg, setMsg] = useState('')
    const [msgTitle, setMsgTitle] = useState('')
    const [isLoading, setIsLoading] = useRecoilState(loading)
    const [notiList, setNotiList] = useRecoilState(notiList0)

    const handleUpload = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const result = await post(`/lead/${ids}/note`, {
            "title": msgTitle,
            "note": msg
        })
        if (result.status == 201) {
            setNotiList(prevValue => [
                ...prevValue,
                {text: result.data.message, isSucc: true} //"Note successfully created"
            ])
            setMsg('')
            setMsgTitle('')
            await updateNotes()
            setAddNote(false)
        } else {
            setNotiList(prevValue => [
                ...prevValue,
                {text: result.data.message, isSucc: false} //"Something went wrong"
            ])
        }
        setIsLoading(false)
    }
    const [notes, setNotes] = useState(null)
    const [page, setPage] = useState(1)
    const updateNotes = async () => {
        const result = await get(`/lead/${ids}/notes?page=1`)
        if (result.status == 200) {
            setNotes(result.data)
        }
        //setNotes
    }

    const moreNotes = async () => {
        const result = await get(`/lead/${ids}/notes?page=${page + 1}`)
        if (result.status == 200) {
            setNotes(prevValue => {
                return {
                    ...prevValue,
                    data: [...prevValue.data, ...result.data.data]
                }
            })
        }
        setPage(prevValue => prevValue + 1)
    }

    useEffect(() => {
        updateNotes()
    }, [ids])

  return (
    <div className='flex flex-col space-y-2'>
        <div className='flex flex-row justify-between items-center h-10'>
            <div className='space-x-2 flex flex-row items-center'>
                <span className='text-2xl font-semibold'>
                    Notes
                </span>
                <span className='bg-gray-200 px-4 py-[6px] rounded-md'>
                    {notes != null ? notes.total : 0}
                </span>
            </div>
            {
                !addNote ? (
                    <button 
                    onClick={() => setAddNote(true)} 
                    className='text-basic hover:text-white px-3 py-2 hover:bg-basic rounded-lg font-semibold flex flex-row justify-center items-center space-x-1'
                    disabled={addNote}
                    >
                        <AiOutlinePlus className='relative top-[1px]'/>
                        <span>Add note</span>
                    </button>
                ) : (
                    <></>
                )
            }
        </div>
        {
            addNote && (
                <form className='card flex flex-col' onSubmit={handleUpload}>
                    <div className='flex flex-col space-y-2 p-4 text-gray-700'>
                        <input value={msgTitle} onChange={(e) => setMsgTitle(e.target.value)} className='inp shadow-none text-[15px] px-4 py-3 text-md text-gray-700' placeholder='Title' required/>
                        <textarea   value={msg} onChange={(e) => setMsg(e.target.value)} className='rounded-lg border-gray-300 p-4 resize-none h-[150px]' placeholder='Type your note' required>
                        </textarea>
                    </div>
                    <div className='p-4 flex flex-row justify-end'>
                        <button  onClick={() => setAddNote(false)}  className="flex items-center hover:underline justify-center px-4 py-2 text-sm font-medium text-basic w-auto focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                            Cancel
                        </button>
                        <button type="submit" className="space-x-1 text-white bg-basic hover:bg-basic_hov flex flex-row items-center justify-center px-4 py-2 text-sm font-medium rounded-lg focus:ring-4 focus:ring-violet-300 focus:outline-none">
                            <span>
                                Create note
                            </span>
                        </button>
                    </div>
                </form>   
            )
        }
        <div className='flex flex-col space-y-4'>
            <div className={`space-y-4 overflow-x-hidden text-gray-700 overflow-y-auto flex flex-col`}>
                {
                    notes == null
                    ? (
                        [1,2].map((z) => (
                            <NoteSkele key={z} />
                        ))
                    ) : (  
                        notes.data.map((note, i) => (
                            <NoteDisplay key={i} note={note} updateNotes={updateNotes} ids={ids} />
                        ))
                    )
                }
            </div>
            {
                (notes != null && notes.total > notes.data.length) && (
                    <div className='flex justify-center'>
                        <button onClick={moreNotes} className='but rounded-full text-2xl p-2'>
                            <AiOutlinePlus />
                        </button>
                    </div>
                )
            }
        </div>
    </div>
  )
}



function NoteSkele() {
    return (
        <div className='card flex flex-col flex-card'>
            <div className='font-semibold border-b w-full flex flex-row justify-between p-4'>
                <div className='flex flex-row items-center space-x-2'>
                    <div className='rounded-full bg-gray-100 w-[30px] h-[30px] text-blu flex items-center justify-center'>
                        <PiNoteLight className='text-[19px] relative left-[1px]' />
                    </div>
                    <span>
                        Note by
                    </span>
                    <span className='w-[100px]'>
                        <Skeleton className='h-5' />
                    </span>
                </div>
                <div className='flex flex-row justify-center items-center space-x-1'>
                    <span className='w-[70px]'>
                        <Skeleton />
                    </span>
                </div>
            </div>
            <div className='flex flex-row justify-between items-center'>
                <div className={`p-4 w-full`}>
                    <Skeleton />
                </div>
            </div>

        </div>
    )
}


function NoteDisplay({note, updateNotes, ids}) {
    const [popWin, setPopWin] = useRecoilState(popWin0)
    const [notiList, setNotiList] = useRecoilState(notiList0)
    const isTextLong = (text) => {
        const lines = text.split('\n');
        if (text > 100) return true;
        return lines.length > 1;
    };

    const [isLoading, setIsLoading] = useRecoilState(loading)
    const [showFull, setShowFull] = useState(false)
    const [isLong, setIsLong] = useState(isTextLong(note.note))


    const handleDelete = async (e,  item) => {
        setIsLoading(true)


        const newFunc = async () => {
            const result = await del(`/lead/${ids}/note/${item.id}`)
            if (result.status == 200) {
                setNotiList(prevValue => [
                    ...prevValue,
                    {text: result.data.message, isSucc: true} //'Note deleted successfully'
                ])
                await updateNotes()
            } else {
                setNotiList(prevValue => [
                    ...prevValue,
                    {text: result.data.message, isSucc: false}
                ])
            }
        }

        setPopWin({
            disable: true,
            msg: 'Are you sure you want to delete this Note?',
            func: newFunc
        })



        setIsLoading(false)
    }

    return (
        <div className='card flex flex-col flex-card'>
            <div className='font-semibold border-b w-full flex flex-row justify-between p-4'>
                <div className='flex flex-row items-center space-x-2'>
                    <div className='rounded-full bg-gray-100 w-[30px] h-[30px] text-blu flex items-center justify-center'>
                        <PiNoteLight className='text-[19px] relative left-[1px]' />
                    </div>
                    <span>
                        {note.title}
                    </span>

                    <Link className='font-bold hover:underline text-gray-500' to={`/users/${note.user.id}`}>
                        @{note.user.name}
                    </Link>
                </div>
                <div className='flex flex-row justify-center items-center space-x-1'>
                    <FiCalendar className='text-gray-500' />
                    <span>
                        {note.formatted_created_at}
                    </span>
                    <button className='hoverEffect hover:bg-red-600 hover:text-white p-1' onClick={(e) => handleDelete(e, note)}>
                        <RiDeleteBin5Line />
                    </button>    
                </div>
            </div>
            <div className='flex flex-row justify-between items-center'>
                <div className={`p-4 text-gray-500 whitespace-pre-wrap`}>
                    {
                        showFull ? (
                            note.note
                        ) : (
                            isLong ? (
                                note.note.split('\n').length > 1 ? (
                                    note.note.split('\n')[0] + '...' 
                                    ): (
                                    note.note.substring(0,100) + '...'
                                )
                            ) : (
                                note.note
                            )
                        )
                    }
                </div>
                {
                    !showFull && (isLong) && (
                        <span className='px-4 min-w-[120px] font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline' onClick={() => setShowFull(true)}>Read more...</span>
                    )
                }
            </div>
            {
                showFull && (
                    <div className='flex justify-end'>
                        <span className='p-4 font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline' onClick={() => setShowFull(false)}>Read Less...</span>
                    </div>
                )
            }
        </div>
    )
}