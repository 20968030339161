import { useEffect, useState } from "react"
import SelectProMulty from "../inps/SelectProMulty-new"
import langIsoToName from "../../lib/langIsoToName"


const SelectLangs = ({choosedLang, setChoosedLang, setChoosedOption}) => {
    //const [choosedLang, setChoosedLang] = useState([])
    const [listLang, setListLang] = useState(Object.keys(langIsoToName).map(isoKey => {return {id: isoKey,name: langIsoToName[isoKey]}}))

    const handleChoose = (userObj, isInc) => {
        if (!isInc) {
            setChoosedLang(prevValue => [...prevValue, userObj])
        } else {
            setChoosedLang(prevValue => prevValue.filter(prev => prev.id !== userObj.id))
        }
    }


    const func = (userObj, isInc) => {
        setChoosedOption(userObj, isInc, 'lang')
    }

    return (
        <>
            <SelectProMulty allBut={false} randomId={`th-lang-leads`} optionsData={listLang} choosedOption={choosedLang} setChoosedOption={typeof setChoosedOption === 'function' ? func : handleChoose} titleKey={'language'} />
        </>
    )
}

export default SelectLangs