import { get, post } from '../../lib/apiHandler';
import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai';
import { IoCreate } from 'react-icons/io5';
import { notiList0, loading, currentUser0 } from '../../lib/states';
import { useRecoilState } from 'recoil';
import range from '../../lib/range';
import Tr from './DropEdit';
import Skeleton from 'react-loading-skeleton'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function TableTransClient({title, ths, ids, clientId}) {
    const [notiList, setNotiList] = useRecoilState(notiList0)
    const [drop, setDrop] = useState(false)
    const [amount, setAmount] = useState('')
    const [isLoading, setIsLoading] = useRecoilState(loading)
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

    const [transactionsData, setTransactionsData] = useState(null)
    const [pageCountT, setPageCountT] = useState(1)
    const [pageIndexT, setPageIndexT] = useState(1)

    const getTransactionsData = async () => {
        setIsLoading(true)
        setTransactionsData(null)
        const result = await get(`/lead/${ids}/transactions?page=${pageIndexT}`)
        setPageCountT(result.data.last_page)
        setTransactionsData(result.data.data)
        setIsLoading(false)
    }
    
    useEffect(() => {
        if (ids != '') {
            getTransactionsData()
        }
    }, [pageIndexT, ids])
    
    const uploadTrans = async () => {
        setIsLoading(true)
        const result = await post(`/lead/${ids}/transaction`, {
            "amount": amount,
            "date": selectedDate != null ? formatDate(selectedDate) : formatDate(new Date())
        })
        if (result.status == 201) {
            setDrop(false)
            setAmount('')
            setSelectedDate(null)
            setNotiList(prevValue => [...prevValue, {text: "Transaction successfully upload", isSucc: true}])
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}]) //"Something went wrong"
        }
        getTransactionsData();
    }

    const [selectedDate, setSelectedDate] = useState(null);

  return (
    <div className='flex flex-col space-y-2'>
        <div className='space-x-2 flex flex-row items-center justify-between'>
            <span className='text-2xl font-semibold'>
                {title}
            </span>
            {
                !drop && (
                    <button 
                        onClick={() => setDrop(prevValue => !prevValue)} 
                        className='but-emp space-x-1'
                        disabled={false}
                    >
                        <>
                            <AiOutlinePlus className='relative top-[1px]'/>
                            <span>Add trasaction</span>
                        </>
                    </button>
                )
            }
        </div>
        {
            drop && (
                <div className='card p-4 flex flex-col space-y-2 justify-between'>
                    <div className='flex flex-row space-x-4 items-center'>
                        <div className='flex flex-col'>
                            <span className='text-sm font-semibold text-gray-600'>Action Date</span>
                            <DatePicker
                            className='inp z-20'
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            dateFormat="yyyy-MM-dd"
                            />
                        </div>
                        <div className='flex flex-col'>
                            <span className='text-sm font-semibold text-gray-600'>Amount</span>
                            <input value={amount} onChange={(e) => setAmount(e.target.value)} className='inp' />
                        </div>
                    </div>
                    <div className='flex flex-row space-x-2'>
                        {
                            drop && (
                                <div className='flex flex-row w-full space-x-2 justify-end'>
                                    <button onClick={() => uploadTrans()} className='but space-x-1'>
                                        <IoCreate />
                                        <span>
                                            Create Transaction
                                        </span>
                                    </button>
                                    <button 
                                        onClick={() => setDrop(prevValue => !prevValue)} 
                                        className='but-emp space-x-1'
                                        disabled={false}
                                    >
                                        <>
                                            <span>Cancel</span>
                                        </>
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
            )
        }
        <div className='card min-h-[140px] max-h-[400px] sm:max-h-[calc(100vh-370px)] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-900 scrollbar-transparen'>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 the-tab">

                {/*  z-10  */}
                <thead className="sticky top-0 text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                    <tr className='bg-gray-50'>
                        {
                            ths.map((title, i) => (
                                <th key={i} scope="col" className={`px-4 py-3 bg-gray-50`}>
                                    {title}
                                </th>
                            ))
                        }
                        {
                            currentUser.access_level >= 1 && (
                                <th scope="col" className={`px-4 py-3 bg-gray-50 w-[45px]`}>
                                    
                                </th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        transactionsData != null
                        ? (
                            transactionsData.map((dat, i) => {
                                return (
                                    <Tr key={i} clientId={clientId} i={i} ths={ths} dat={dat} getTransactionsData={getTransactionsData} />  
                                )
                            })
                        ) : (
                            <>
                            <tr>
                                {
                                    ths.map((th, z) => <td key={z} className='px-4 py-4'><Skeleton /></td>)
                                }
                            </tr>
                            <tr>
                                {
                                    ths.map((th, z) => <td key={z} className='px-4 py-4'><Skeleton /></td>)
                                }
                            </tr>
                            </>
                        )
                    }
                </tbody>
            </table>
            <nav aria-label="Page navigation example" className='flex justify-end p-2 sticky bottom-0 bg-gray-50'>
                <div className="flex flex-row text-sm [&>span]:cursor-pointer">
                    {
                        pageCountT < pageIndexT
                        && <span onClick={() => setPageIndexT(prevValue => prevValue - 1)} className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 ">Previous</span>            
                    }
                    {
                        range(0, pageCountT).map((item, i) => (
                            true
                            ?   (
                                <span key={i} aria-current="page" className="flex !cursor-default items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 ">{i+1}</span>
                            ) : (
                                <span key={i} onClick={() => setPageIndexT(i+1)} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">{i + 1}</span>
                            )
                        ))
                    }
                    {
                        pageCountT > pageIndexT
                        && <span onClick={() => setPageIndexT(prevValue => prevValue + 1)} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 ">Next</span>
                    }
                    
                </div>
            </nav>
        </div>
    </div>
  )
}


function formatDate(date) {
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0'); 
  
    return `${year}-${month}-${day}`;
}