import React, {useEffect, useState} from 'react'
import TabPageIndex from './TabPageIndex';
import Sel from '../inps/Sel';
import { useRecoilState } from 'recoil';
import { officesList0, notiList0, toForce0, currentUser0 } from '../../lib/states';
import { get, put } from '../../lib/apiHandler';
import SelectPro from '../inps/SelectPro-new';
import SelectProMulty from '../inps/SelectProMulty-new';

export default function TableBott({
    handlerPage,
    objFilter,
    currentData,
    setCurrentData,
    setObjFilter,
    listCheked, setListCheked,
    setUploadForce,
    isUsersPage,
    noTotal
}) {
    const [toForce, SetToForce] = useRecoilState(toForce0)

    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

    // GENERAL
    const empObj = {name: '', id: null}
    const empObjForce = {name: '-------', id: ''}

    const [notiList, setNotiList] = useRecoilState(notiList0)
    
    const uploadManyClients = async () => {
        var listIds = []
        for (var x in listCheked) {
            listIds.push(currentData.data[listCheked[x]].id)
        }
        var obj = {
            "lead_ids": listIds,
        }
        if (choosedOffice.id != null) {
            obj.office_id = choosedOffice.id
        }
        if (choosedDesk.id != null) {
            obj.desk_id = choosedDesk.id
        }
        if (choosedUser.id != null) {
            obj.user_id = choosedUser.id
        }
        if (theStatus != '') {
            obj.status = theStatus 
        }
        const result = await put('/leads', obj)
        if (result.status == 200) {
            setTheStatus('')
            setChoosedOffice(empObj)
            setChoosedDesk(empObj)
            setChoosedUser(empObj)
            setUploadForce(prevValue => !prevValue)
            //setListCheked([])
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: true}]) //"The selected leads have been successfully updated"
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}]) //"Something went wrong"
        }


    }

    // STATUS
    const [theStatus, setTheStatus] = useState('')
    const statusOpt = ["", "New", "No answer", "Call again", "Not Interested", "Wrong Details", "Meeting", 'In progress', "Deposit"]

    // OFFICE
    const [choosedOffice, setChoosedOffice] = useState(empObj)
    const [officesList, setOfficesList] = useRecoilState(officesList0)
    const handleChooseOffice = (choosedOfficeX) => {
        setChoosedOffice(choosedOfficeX)
        if (choosedOfficeX.id != '') {
            setDeskList(choosedOfficeX.desks)
            setChoosedDesk(empObj)
            setChoosedUser(empObj)
        }
    }
    // DESK
    const [choosedDesk, setChoosedDesk] = useState(empObj)
    const [DeskList, setDeskList] = useState([])
    const handleChooseDesk = async (choosedDeskX) => {
        if (choosedDeskX.id != null) {
            setChoosedDesk(choosedDeskX)
            setChoosedUser(empObj)
            const result = await get(`/desk/${choosedDeskX.id}/users`)
            setUsersList(result.data.users)
        } else {
            setChoosedDesk(empObj)
            setChoosedUser(empObj)
            setUsersList([])

        }
    }
    // USER
    const [choosedUser, setChoosedUser] = useState(empObj)
    const [usersList, setUsersList] = useState([])
    const handleChooseUser = async (choosedUserX) => {
        setChoosedUser(choosedUserX)
    }
    


    /////////////////////////// USER PAGE ////////////////////
    // LEVEL
    const [theLevel, setTheLevel] = useState('')
    const levelOpt = ["", "Agent", "Desk Manager", "Office Manager", "Admin"]


    const getLevels = () => {
        var tempLevels = ['', 'Agent', 'Desk Manager', 'Office Manager',  'Admin']

        var retList = []
        for (var x=0; x<currentUser.access_level+1; x++) {
            retList.push(tempLevels[x])
        }

        return retList;
    }

    // OFFICES
    const [choosedOffices, setChoosedOffices] = useState([])
    const handleChooseOffices = (objOffiX, isInc) => {
        if (isInc) {
            setChoosedOffices(prevValue => prevValue.filter(prev => prev.id != objOffiX.id))
        } else {
            setChoosedOffices(prevValue => [objOffiX, ...prevValue])
        }
    }
    useEffect(() => {
        var list = [];
        for (var x in choosedOffices) {
            list.push(...choosedOffices[x].desks)
        }
        setDesksList(list)
    }, [choosedOffices])
    // DESKS
    const [choosedDesks, setChoosedDesks] = useState([])
    const [desksList, setDesksList] = useState([])

    const handleChooseDesks = (objDeskX, isInc) => {
        if (isInc) {
            setChoosedDesks(prevValue => prevValue.filter(prev => prev.id != objDeskX.id))
        } else {
            setChoosedDesks(prevValue => [objDeskX, ...prevValue])
        }
    }

    const uploadManyUsers = async () => {
        var listIds = []
        for (var x in listCheked) {
            listIds.push(currentData.data[listCheked[x]].id)
        }
        var obj = {
            "user_ids": listIds,
        }
        if (choosedOffices.length >  0) {
            obj.office_ids = choosedOffices.map(offi => offi.id)
        }
        if (choosedDesks.length >  0) {
            obj.desk_ids = choosedDesks.map(des => des.id)
        }
        if (theLevel !=  '') {
            obj.access_level = levelOpt.findIndex((lev) => lev == theLevel)
        }
        const result = await put('/users', obj)
        if (result.status == 200) {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: true}]) //"The selected Users have been successfully updated"
            setUploadForce(prevValue => !prevValue)
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}]) //"Something went wrong"
        }
    }


    const [from, setFrom] = useState(currentData !== null ? currentData.from : 0);

    useEffect(() => {
        if (currentData !== null) {
            setFrom(currentData.from)
        }
    }, [currentData])


    const retTo = () => {
        if (noTotal) {
            return currentData !== null ? currentData.to : 0
        } else {
            return currentData !== null ? currentData.to-1 : 0
        }
    }
    
    const [pageInp, setPageInp] = useState(typeof currentData?.current_page === 'undefined' ? 1 : currentData?.current_page)
    useEffect(() => {
        if (currentData !== null) {
            setPageInp(currentData?.current_page)
        }
    }, [currentData?.current_page])

    useEffect(() => {
        if (currentData?.current_page !== Number(pageInp)) {
            var ind = pageInp
            if (pageInp > currentData?.last_page) {
                ind = currentData?.last_page
            }
            setObjFilter(prevValue => {
                return {
                    ...prevValue,
                    page: ind
                }
            })

            const timer = setTimeout(() => {
                SetToForce(prev => !prev)
            }, 1200);
            
            return () => {
              clearTimeout(timer);
            };
        }
        
    }, [pageInp])

  return (
    <div className='bott-table flex border-t items-center justify-between p-4 sticky bottom-0 left-0 bg-gray-50 z-10'>
            <div className='flex flex-row items-center space-x-[9px] text-md'>
                {
                    listCheked.length == 0
                    ? (
                        <>
                        <select className='inp font-semibold bg-gray-50 px-1 w-[70px]' onChange={handlerPage} value={objFilter.per_page}>
                            {
                                [10,50,100,200,500,1000].map((item) => (
                                    <option key={item} value={item}>{item}</option>
                                ))
                            }
                        </select>
                        <span className='text-gray-600'>
                            Showing
                        </span>
                        <span className='font-semibold'>
                            {from > retTo() ? 0 : from}
                            -
                            {retTo()}
                        </span>
                        <span className='text-gray-600'>
                            of
                        </span>
                        <span className='font-semibold'>
                            {currentData != null ? currentData.total : 0}
                        </span>
                        </>
                    ) : (
                        isUsersPage 
                        ? ( // USER PAGE ////////////////////
                            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 lg:gap-4 table-bott'>
                                <div>
                                    <label htmlFor="access_level" className="block text-sm font-semibold text-gray-900">
                                        access level
                                    </label>
                                    <Sel ids={'access_level'} val={theLevel} setVal={(e) => setTheLevel(e.target.value)} opts={getLevels()} optsVal={getLevels()} />
                                </div>

                                <div>
                                    <label htmlFor="offices" className="block text-sm font-semibold text-gray-900">
                                        Offices
                                    </label>
                                    <SelectProMulty randomId={'user-off'} optionsData={[...officesList]} choosedOption={choosedOffices} setChoosedOption={handleChooseOffices} />
                                </div>

                                {
                                    choosedOffices.length != 0 && (
                                        <div>
                                            <label htmlFor="desks" className="block text-sm font-semibold text-gray-900">
                                                Desks
                                            </label>
                                            <SelectProMulty randomId={'user-des'} optionsData={[...desksList]} choosedOption={choosedDesks} setChoosedOption={handleChooseDesks} />
                                        </div>
                                    )
                                }
                                <div className='flex items-end'>
                                    <button className='but' onClick={uploadManyUsers}>
                                        Save
                                    </button>
                                </div>

                            </div>
                        ) : ( // LEADPAGE ///////////////////////
                            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 lg:gap-4 table-bott'>
                                <div>
                                    <label htmlFor="status" className="block text-sm font-semibold text-gray-900">
                                        Status
                                    </label>
                                    <Sel ids={'status'} val={theStatus} setVal={(e) => setTheStatus(e.target.value)} opts={statusOpt} optsVal={statusOpt} />
                                </div>


                                <div>
                                    <label className="block text-sm font-semibold text-gray-900">
                                        Office
                                    </label>
                                    <SelectPro randomId={'row-1'} optionsData={[empObj, empObjForce, ...officesList]} choosedOption={choosedOffice}  setChoosedOption={handleChooseOffice} />
                                </div>

                                {
                                    choosedOffice.id != null && choosedOffice.id != ''  && (
                                        <div>
                                            <label className="block text-sm font-semibold text-gray-900">
                                                Desk
                                            </label>
                                            <SelectPro randomId={'row-2'} optionsData={[empObj, ...DeskList]} choosedOption={choosedDesk}  setChoosedOption={handleChooseDesk} />
                                        </div>
                                    )
                                }
                                {
                                    choosedDesk.id != null  && choosedDesk.id != '' && (
                                        <div>
                                            <label className="block text-sm font-semibold text-gray-900">
                                                User
                                            </label>
                                            <SelectPro randomId={'row-3'} optionsData={[empObj, ...usersList]} choosedOption={choosedUser}  setChoosedOption={handleChooseUser} />
                                        </div>
                                    )
                                }




                                <div className='flex items-end'>
                                    <button className='but' onClick={uploadManyClients}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        )
                    )
                }
            </div>
            {
                currentData !== null ? (
                    <div className='flex flex-row items-center space-x-2'>
                        <input className='inp h-[35px] w-[50px] text-center' value={pageInp} onChange={(e) => {setPageInp(e.target.value);}} />
                        <TabPageIndex objFilter={objFilter} setObjFilter={setObjFilter} currentData={currentData} setCurrentData={setCurrentData} />
                    </div>
                ) : (
                    <nav aria-label="Page navigation example ">
                        <ul className="inline-flex items-stretch -space-x-px">

                            <li>
                                <span className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                </span>
                            </li>


                            {/* <li>
                                <span style={{
                                    backgroundColor: 'white'
                                }} className={`flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                                >
                                    ...
                                </span>
                            </li> */}

                            

                            <li>
                                <span className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                </span>
                            </li>

                        </ul>
                    </nav>
                )
            }
        </div>
  )
}
