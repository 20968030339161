import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { AiOutlinePlus } from 'react-icons/ai';
import Epie from '../../components/charts/Epie';
import Epass from '../../components/charts/Epass';

import { useRecoilState } from 'recoil';
import { loading, notiList0, officesList0, currentUser0, popWin0 } from '../../lib/states';
import { ImEnter } from 'react-icons/im'

import { IoClose } from "react-icons/io5";

import { useParams } from 'react-router-dom';
import { del, get, post, put } from '../../lib/apiHandler';

import { updateOfficeList, convertValuesToNumber, formDataToObject } from '../../lib/funcs'

import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SelectProMulty from '../../components/inps/SelectProMulty-new';
import country_two_iso from '../../lib/country_two_iso';
import { RolletaOffice } from '../../components/RolletaOfiice';
import { ClearLeads } from '../../components/clearLeads';



export default function OfficePage() {
  const [isLoading, setIsLoading] = useRecoilState(loading)
  const [notiList, setNotiList] = useRecoilState(notiList0)
  const [officesList, setOfficesList] = useRecoilState(officesList0)
  const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
  const [add, setAdd] = useState(false)
  const [text, setText] = useState('')


  const location = useLocation();
  const path = location.pathname + location.search;


  const router = useNavigate();
  const {officeId} = useParams();

  
  // const getOffice = () => {
  //   if (officesList != null) {
  //     const obj = officesList.find((offi) => offi.id == officeId)
  //     if (obj != null) {
  //       console.log(obj)
  //       return obj
  //     } else {
  //       return false
  //     }
  //   }
  // }

  const getOfficeById = async () => {
    const result = await get(`/office/${officeId}`)
    if (result.ok) {
      return result.data
    } else {
      setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
    }
  }


  const [currentOffice, setCurrentOffice] = useState(null)


  useEffect(() => {
    const func = async () => {
      setCurrentOffice(await getOfficeById())
    }
    func()
  }, [])


  const createDesk = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const result = await post('/desks', {
      name: text,
      office_id: officeId
    })

    if (result.ok) {
      setCurrentOffice(await getOfficeById())
      await updateOfficeList(setOfficesList);

      setAdd(false)
      setNotiList(prevValue => [
        ...prevValue,
        {text: result.data.message, isSucc: true}
      ])
    } else {
      setNotiList(prevValue => [
        ...prevValue,
        {text: result.data.message, isSucc: false}
      ])
    }
    setIsLoading(false)
  }


  ////////////// edit office //////////
  var startOfficeInfo = {
    office_name: '',
    office_api: '',
    desks: []
  }
  if (currentOffice !== null) {
    startOfficeInfo = {
      office_name: currentOffice.name,
      office_api: currentOffice.click2call,
      desks: []
    }
  }

  const [showEdit, setShowEdit] = useState(false)
  const [officeInfo, setOfficeInfo] = useState(startOfficeInfo)
  
  const handleOfficeInfo = (e) => {
    setOfficeInfo(prevValue => {
      return {
        ...prevValue, 
        [e.target.getAttribute('name')]: e.target.value
      }
    })
  }

  const updateOfficeInfo = async () => {
    setIsLoading(true)
    const result = await put(`/office/${currentOffice.id}`, {
      name: officeInfo.office_name,
      click2call: officeInfo.office_api
    })
    if (result.ok) {
      await updateOfficeList(setOfficesList)
      setShowEdit(false)
      setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: true}]) //"The office details have been successfully updated"
    } else {
      setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
    }
    setIsLoading(false)
  }


  //// delete office ////

  const [popWin, setPopWin] = useRecoilState(popWin0)
  const deleteOffice = async () => {
    const newFunc = async () => {
      const result = await del(`/office/${currentOffice.id}`)
      if (result.status == 200) {
        setNotiList(prevValue => [
          ...prevValue,
          {text: result.data.message, isSucc: true} //"Office successfully deleted"
        ])
        router('/offices')
      } else {
        setNotiList(prevValue => [
          ...prevValue,
          {text: result.data.message, isSucc: false} //"Something went wrong"
        ])
      }
    }


    setPopWin({
        disable: true,
        msg: 'Are you sure you want to delete this Office?',
        func: newFunc
    })
  }


  if (true) {
    
      if (currentOffice === false) {
        router('/offices')
      } 
      // else if ((currentUser.access_level < 3 && currentOffice.desks.length == 1))  {//////////
      //   router(`${path}/${currentOffice.desks[0].id}`)
      // } else {
        return (
          <div className='flex flex-col space-y-4 h-min w-full '>
            <div className='flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0'>
              <div className='lg:w-3/5 w-full  flex flex-col space-y-4'>
                <div className='flex flex-row justify-between items-center'>
                  <span className='text-2xl font-semibold min-w-[100px]'>
                    {
                      currentOffice !== null ? (
                        currentOffice.name
                      ) : (
                        <Skeleton />
                      )
                    }
                  </span>
    
                  {
                    !showEdit ? (
                      currentUser.access_level >= 3 && (
                        <button className='but h-[36px] w-[36px] p-0 rounded-full' 
                          onClick={() => {
                            setShowEdit(prevValue => !prevValue);
                            setOfficeInfo(startOfficeInfo)
                          }}
                        >
                          <MdModeEdit className='text-[20px]'/>
                        </button>
                      )
                    ) : (
                      <div className='space-x-1 flex flex-row'>
                        <>
                          {
                            currentUser.access_level == 4 && (
                              <button onClick={deleteOffice} className='but-red font-semibold space-x-1'>
                                <MdDelete className='text-[18px]'/>
                                <span className='text-[16px]'>
                                  delete
                                </span>
                              </button>
                            )
                          }
                          <button onClick={updateOfficeInfo} className='but font-semibold space-x-1'>
                            <FaRegSave className='text-lg'/>
                            <span className='text-[16px]'>
                              Save
                            </span>
                          </button>
                          <button className='but-emp border-none rounded-full bg-gray-50 w-[36px] h-[36px] p-0'
                            onClick={() => {
                              setShowEdit(prevValue => !prevValue);
                              setOfficeInfo(startOfficeInfo)
                            }}
                          >
                            <IoClose className='text-[18px]' />
                          </button>
                        </>
                        
                      </div>
                    )
                  }
                </div>
                {
                  showEdit && (
                    <div className='card p-4'>
                      <form className='space-y-2'>
                        <input className='inp' placeholder="Office's name" value={officeInfo.office_name} name='office_name' onChange={handleOfficeInfo} />
                        <input className='inp' placeholder="api link" value={officeInfo.office_api} name='office_api' onChange={handleOfficeInfo} />
                      </form>
                    </div>
                  )
                }
                <div className='flex flex-row space-x-4'>
                  <div className='card w-1/3 h-[100px] flex flex-col space-y-1 justify-center items-center'>
                    <span className="text-sm text-gray-800">Agent amount</span>
                    <span className="text-xl font-semibold min-w-[50px]">
                      {
                        currentOffice !== null ? (
                          currentOffice.agents_count
                        ) : (
                          <Skeleton />
                        )
                      }
                    </span>
                  </div>
                  <div className='card w-1/3 h-[100px] flex flex-col space-y-1 justify-center items-center'>
                    <span className="text-sm text-gray-800">Leads amount</span>
                    <span className="text-xl font-semibold min-w-[50px]">
                      {
                        currentOffice !== null ? (
                          currentOffice.leads_count
                        ) : (
                          <Skeleton />
                        )
                      }
                    </span>
                  </div>
                  <div className='card w-1/3 h-[100px] flex flex-col space-y-1 justify-center items-center'>
                    <span className="text-sm text-gray-800">Total transactions</span>
                    <span className="text-xl font-semibold min-w-[50px]">
                      {
                        currentOffice !== null ? (
                          currentOffice.total_transactions
                        ) : (
                          <Skeleton />
                        )
                      }
                    </span>
                  </div>
                  {/* <div className='card w-1/3 h-[100px] flex flex-col space-y-1 justify-center items-center'>
                    <span className="text-sm text-gray-800">Office manager</span>
                    <span className="text-xl font-semibold">****</span>
                  </div> */}
                </div>
                {/* <div className='card h-[300px]'>
                  <Eline />
                </div> */}
                <div className='card h-[300px]'>
                  {
                    currentOffice !== null && (
                      <Epass data0={currentOffice.lead_status_counts} />
                    )
                  }
                </div>

                {
                  currentUser.access_level >= 3 && false && (
                    <>
                      <div className='flex flex-col space-y-2'>
                        <span className='text-2xl font-semibold'>
                          Assign Leads
                        </span>
                        <RolletaOffice officeId={officeId} allDesks={currentOffice?.desks} />
                      </div>
                      <div className='flex flex-col space-y-2'>
                        <span className='text-2xl font-semibold'>
                          Reset Lead Assignments
                        </span>
                        <ClearLeads officeId={officeId} allDesks={currentOffice?.desks} />
                      </div>

                    </>
                  )
                }




              </div>
              <div className='lg:w-2/5 w-full space-y-4 flex flex-col'>
                  <div className='flex flex-row justify-between items-center'>
                    <div className='space-x-2 flex flex-row items-center'>
                        <span className='text-2xl font-semibold'>
                            Desks
                        </span>
                        <span className='bg-gray-200 px-4 py-[6px] rounded-md'>
                            {currentOffice !== null ? currentOffice.desks.length : 0}
                        </span>
                    </div>
                    {
                  !add && currentUser.access_level > 2 && (
                    <button 
                      onClick={() => setAdd(true)} 
                      className='text-basic hover:text-white px-3 py-2 hover:bg-basic rounded-lg font-semibold flex flex-row justify-center items-center space-x-1'
                      disabled={false}
                      >
                        <AiOutlinePlus className='relative top-[1px]'/>
                        <span>Add desk</span>
                      </button>
                    )
                  }
                  </div>
                  {
                      add && (
                          <form className='card flex flex-row p-4 justify-between'>
                              <input value={text} onChange={(e) => setText(e.target.value)} placeholder="Desk's name" className='inp max-w-[300px] px-3 text-lg text-gray-700' />
                              <div className='flex flex-row justify-end'>
                                  <button  onClick={() => setAdd(false)}  className="flex items-center hover:underline justify-center px-4 py-2 text-sm font-medium text-basic w-auto focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                      Cancel
                                  </button>
                                  <button type="submit" onClick={createDesk} className="space-x-1 text-white bg-basic hover:bg-basic_hov flex flex-row items-center justify-center px-4 py-2 text-sm font-medium rounded-lg focus:ring-4 focus:ring-violet-300 focus:outline-none">
                                    <span>
                                        Create desk
                                    </span>
                                  </button>
                              </div>
                          </form>   
                      )
                  }
                  {
                    currentOffice !== null 
                    ? (
                      currentOffice.desks.map((item, i) => (
                          <div key={i} className='card flex flex-row'>
                            <div className='w-3/5'>
                              <div className='flex flex-col space-y-1 justify-center items-center py-6'>
                                <div className='relative right-[6px]'>
                                  <Epie data0={item.lead_status_counts} />
                                </div> 
                                <button onClick={() => router(`${path}/${item.id}`)} className='but-emp font-semibold text-lg px-4 py-1 flex flex-row items-center justify-center space-x-2'>
                                  <span>
                                    {item.name}
                                  </span>
                                  <ImEnter className='relative top-[2px]' />
                                </button>
                                {/* <span onClick={() => router(`${router.asPath}/${i}`)} className='hover:underline cursor-pointer text-3xl font-semibold'>
                                  {item.name}
                                </span> */}
                              </div>
                            </div>
                            <div className='border-l w-2/5 flex flex-col justify-between [&>div]:flex-auto [&>div]:border'>
                              <div className=' w-full flex flex-col py-4 justify-center items-center space-y-1'>
                                <span className="text-xl text-center font-semibold">Total transactions</span>
                                <span className="text-sm text-gray-800">{item.transaction_count}</span>
                              </div>
                              <div className=' w-full flex flex-col py-4 justify-center items-center space-y-1'>
                                <span className="text-xl text-center font-semibold">Leads amount</span>
                                <span className="text-sm text-gray-800">{item.leads_count}</span>
                              </div>
                            </div>
                          </div>
                        ))
                    )
                    : (
                      [1,2].map((item, i) => (
                        <div key={i} className='card flex flex-row'>
                          <div className='w-3/5'>
                            <div className='flex flex-col space-y-10 justify-center items-center py-6'>
                              <div className=''>
                                <Skeleton
                                      circle
                                      height="164px"
                                      width="164px"
                                  />
                              </div> 
                              <span className='w-[150px]'>
                                <Skeleton />
                              </span>
                            </div>
                          </div>
                          <div className='border-l w-2/5 flex flex-col justify-between [&>div]:flex-auto [&>div]:border'>
                            <div className=' w-full flex flex-col py-4 justify-center items-center space-y-1'>
                              <span className="text-xl text-center font-semibold">Total products</span>
                              <span className="w-[100px]"><Skeleton /></span>
                            </div>
                            <div className=' w-full flex flex-col py-4 justify-center items-center space-y-1'>
                              <span className="text-xl text-center font-semibold">Total products</span>
                              <span className="w-[100px]"><Skeleton /></span>
                            </div>
                          </div>
                        </div>
                      ))
                    )
                  }
              </div>
            </div>
          </div>
        )
      // }
  }    




  



}

            //<div className='card p-4 h-[80px]' onClick={() => router(`${router.asPath}/${i}`)}>
          // <button 
          //   onClick={() => {}} 
          //   className='text-basic hover:text-white px-3 py-2 hover:bg-basic rounded-lg font-semibold flex flex-row justify-center items-center space-x-1'
          //   disabled={false}
          //   >
          //     <AiOutlinePlus className='relative top-[1px]'/>
          //     <span>Add office</span>
          // </button>