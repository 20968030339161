import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { loading, currentUser0 } from '../../lib/states';

import { RiDashboard2Fill, RiLogoutBoxRLine } from 'react-icons/ri';
import { MdPermContactCalendar } from 'react-icons/md';
import { FaUserShield } from 'react-icons/fa';
import { BiSolidBriefcase } from 'react-icons/bi';
import { AiFillSetting } from 'react-icons/ai';
import { CgProfile } from 'react-icons/cg'

import { useLocation } from 'react-router-dom';

export default function Menu() {
    //const [isLoading, setIsLoading] = useRecoilState(loading)
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const router = useNavigate();
    //const router = useRouter();
    //const path = router.asPath
    const location = useLocation();
    const path = location.pathname + location.search;



    const [index, setIndex] = useState(0)
    useEffect(() => {
        setIndex(indexMenu('/' + path.split('/')[1], currentUser.access_level))
    }, [path])

    const [drop, setDrop] = useState(false)

    const logout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('clientFilter')
        localStorage.removeItem('userFilter')
        localStorage.removeItem('columns-client')

        window.location.reload(true)
    }

    useEffect(() => {
        const handleBodyClick = (e) => {
            var element = e.target
            var isInside = false;
            while (true) {
                if (`drop-menu-opt` == element.id) {
                    isInside = true
                    break
                }
                element = element.parentElement
                if (element == null) {
                    break
                }
            }
            if (!isInside) {
                setDrop(false)
            }
        };
        document.body.addEventListener('click', handleBodyClick);
        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, [])

  return (
    <>
        <div className='flex-row hidden sm:flex max-h-screen sticky top-0 z-20'>
            <div className='relative'>
                <motion.div
                className='bg-basic h-[48px] w-4 rounded-lg absolute left-[-12px]'
                animate={{
                    y: 29 + index*75 , //isLoading ? [0, 29 + 3*75, 0] : 
                    // x: [0, 50, 0]
                }}
                transition={{
                    //duration: isLoading ? 2 : 1,
                    //repeat: isLoading && Infinity,
                  }}
                ></motion.div>
            </div>
            <div className='border-r text-gray-400 flex flex-col items-center w-[70px] text-[27px] py-5 justify-between'>
                <div className='flex flex-col space-y-1 w-full items-center'>
                    {/* <Icon link="/" icon={<RiDashboard2Fill />} /> */}
                    <Icon link="/clients" icon={<MdPermContactCalendar />} />
                    {
                        currentUser.access_level != 1 && (
                            <Icon link="/users" icon={<FaUserShield />} />
                        )
                    }
                    <Icon link="/offices" icon={<BiSolidBriefcase />} />
                    {/* <Icon link="/mail" icon={<MdOutgoingMail />} /> */}
                </div>
                <div id='drop-menu-opt' className='hover:text-gray-500 active:text-basic cursor-pointer relative'>
                    <div onClick={() => setDrop(prevValue => !prevValue)}>
                        <AiFillSetting />
                    </div>
                    {
                        drop && (
                            <div className='absolute right-[-172px] bottom-0'>
                                <div className='card w-[150px]'>
                                    <ul className='text-[16px] text-gray-800 [&>li]:p-3 '>
                                        <li onClick={() => {setDrop(false); router(`/users/${currentUser.id}`)}} className='hover:bg-gray-50 flex flex-row items-center space-x-3 active:bg-gray-100'>
                                            <CgProfile className='text-[20px]' />
                                            <span>
                                                Profile
                                            </span>
                                        </li>
                                        <li onClick={logout} className='hover:bg-gray-50 flex flex-row items-center space-x-3 active:bg-gray-100'>
                                            <RiLogoutBoxRLine className='text-[20px]' />
                                            <span>
                                                Logout
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>

        {/* MOBILE */}
        <div className='sm:hidden z-20 bg-white border-t w-full h-[70px] flex flex-row text-[35px] text-gray-400 justify-around items-center'>
            {/* <Icon link="/" icon={<RiDashboard2Fill />} /> */}
            <Icon link="/clients" icon={<MdPermContactCalendar />} />
            <Icon link="/users" icon={<FaUserShield />} />
            <Icon link="/offices" icon={<BiSolidBriefcase />} />
        </div>
    </>
  )
}


function Icon({link, icon}) {
    const location = useLocation();
    const path = location.pathname + location.search;
  return (
    <Link to={link} className={`${path == link ? 'text-basic' : 'hover:text-gray-500'} w-full flex justify-center items-center h-[70px]`}>
        {icon}
    </Link>
  )
}

const indexMenu = (path, access_level) => {
    const list = (access_level != 1 ? ['/clients', '/users', '/offices'] : ['/clients', '/offices']);
    const index = list.findIndex((item) => item == path)
    return index
  }
