import React, { PureComponent, useEffect, useState } from 'react';
import { PieChart, Pie, Tooltip, Cell, Sector } from 'recharts';

export default function Example ({data0}) {
    const [activeIndex, setActiveIndex] = useState(0);

    // useEffect(() => {
    //     console.log(typeof data0['New'])
    // }, [])
    
    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    const isEmp = () => {
        if (data0 != null) {
            var count = 0;
            // var list = ['New', 'DVM', 'Call again', 'No answer', 'Not Interested', 'Wrong Details', 'FTD'] // OLD
            var list = ['New', 'Call again', 'No answer', 'Not Interested', 'Wrong Details', 'Deposit', 'In progress', 'Meeting']
            for (var x in list) {
                // if (typeof data0[list[x]] !== 'number') {
                //     //console.log(data0, list[x], data0[list[x]])
                // } else {
                //     console.log(list[x])
                // }
                count += data0[list[x]]
            }
            //console.log('false', count == 0 ? true : false, count)
            return count == 0 ? true : false
        } else {
            //console.log(true)
            return true
        }
    }

    const data = 
    isEmp() 
    ? [
        { name: 'No leads', value: 1 }
    ] : [
        { name: 'New', value: data0['New'] },
        { name: 'Meeting', value: data0['Meeting'] },
        { name: 'Call again', value: data0['Call again'] },
        { name: 'No answer', value: data0['No answer'] },
        { name: 'Not Interested', value: data0['Not Interested'] },
        { name: 'Wrong Details', value: data0['Wrong Details'] },
        { name: 'Deposit', value: data0['Deposit'] },
        { name: 'In progress', value: data0['In progress'] },
    ];

    const COLORS = isEmp() ? ['#e8e8e8'] : ['#c4c2d6', '#74e9ff', '#797fde', '#ffc565', '#ff8f8f', '#073b4c', '#c1f013', '#6fd99f'];

    // const data = [
    //   { name: 'New', value: 100 },
    //   { name: 'Open', value: 300 },
    //   { name: 'In Progress', value: 300 },
    //   { name: 'Open deals', value: 200 },
    //   { name: 'Closed', value: 200 },
    // ];

    const wid = 190;
    return (
        <div className='flex justify-start  cursor-pointer'>
            <PieChart width={wid} height={wid}>
            <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={data}
                cx={95}
                cy={90}
                innerRadius={60}
                outerRadius={80}
                paddingAngle={2}
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={onPieEnter}
            >
                {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
            {/* <Tooltip /> */}
            </PieChart>
        </div>
    );
  
}

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
  //console.log(props);
  return (
    <g className='font-semibold'>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
            {payload.name}
        </text>
        <text x={cx} y={cy + 20} dy={8} textAnchor="middle" fill={fill}>
            {payload.name != 'No leads' && payload.value}
        </text>

        <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius+2}
            outerRadius={outerRadius+2}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
        />
    </g>
  );
};