import React, {useState, useEffect} from 'react'
import Skeleton from 'react-loading-skeleton'
import ThFilter from './ThFilter';
import ThSort from './ThSort';
import range from '../../lib/range'
import { currentUser0, toForce0 } from '../../lib/states';
import Tr from './Tr-new';
import TableBott from './TableBott';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

export default function Table({
    currentData,
    setCurrentData,
    allThs,
    ths,
    objFilter,
    setObjFilter,
    noTotal,
    setUploadForce,
    choosedAfterRem, setChoosedAfterRem,
    listCheked, setListCheked
}) {
    const [toForce, SetToForce] = useRecoilState(toForce0)

    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const [currentDataSlice, setCurrentDataSlice] = useState([])

    useEffect(() => {
        if (currentData !== null) {
            setCurrentDataSlice(noTotal ? currentData.data : currentData.data.slice(0, -1))
        } else {
            setCurrentDataSlice([])
        }
    }, [currentData])
    
    

    const handlerPage = (e) => {
        setObjFilter(prevValue => {
            return {
                ...prevValue,
                per_page: Number(e.target.value),
                page: 1
            }
        })
        SetToForce(prev => !prev)
    }

    useEffect(() => {
        if (currentData != null) {
            if (currentData.data.length-1 == listCheked.length) {
                setCheckAll(true)
            }
        }
    }, [listCheked])

    const [chcekAll, setCheckAll] = useState(false)
    const handleCheckAll = (e) => {
        setCheckAll(e.target.checked)
        var checkList = []
        if (e.target.checked) {
            for (var x in currentData.data) {
                if (typeof currentData.data[x].id !== 'undefined') {
                    checkList.push(Number(x))
                }
            }
        }
        setListCheked(checkList)
    }

    const location = useLocation();
    const path = location.pathname + location.search;
    const [isLeadPage, setIsLeadPage] = useState(path.includes('/users') ? true : false)

    
  return (
    <div className="border relative  flex flex-col justify-between overflow-x-auto min-h-[250px] h-[calc(100vh-150px)] sm:h-[calc(100vh-100px)] shadow-md sm:rounded-lg scrollbar-thin scrollbar-thumb-gray-900 scrollbar-transparent">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 the-tab">
            <thead className="sticky top-0 z-20 text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                <tr className='bg-gray-50 h-[52px]'>
                    {
                        currentUser.access_level > 1 && (
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input checked={chcekAll} onChange={handleCheckAll} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                </div>
                            </th>
                        )
                    }
                    {
                        allThs.map((item, i) => 
                            ths.map((tz) => tz.display).includes(item.display) && (
                            <th key={i} scope="col" className={`${i==(noTotal ? 1 : 1) && 'sticky top-0 left-0'} px-6 py-3 bg-gray-50`}>
                                <div className='flex flex-row items-center space-x-2'>
                                    <span>{item.display}</span>
                                    {
                                        item.display != 'phone' && (
                                            <div className='text-gray-700 flex flex-row [&>div]:hoverEffect [&>div]:flex [&>div]:w-7 [&>div]:h-7 [&>div]:items-center [&>div]:justify-center'>
                                                <ThSort setObjFilter={setObjFilter} item={item.key} />
                                                <ThFilter choosedAfterRem={choosedAfterRem} setChoosedAfterRem={setChoosedAfterRem} index={i} objFilter={objFilter} setObjFilter={setObjFilter} title={item} />
                                            </div>
                                        )
                                    }
                                </div>
                            </th>
                        ))
                    }
                </tr>
            </thead>
            <tbody>
                {  
                    currentData == null 
                    ? (
                        range(0, objFilter.per_page).map((num,c) => (
                            <tr key={c}>
                            {
                                [...ths, "a"].map((key0, z) => (
                                    <td key={z} className={`px-3 py-4`}>
                                        <Skeleton />
                                    </td>
                                ))
                            }
                            </tr>
                        ))
                    ) : (
                        currentDataSlice.map((clientData, i) => (
                            <Tr noTotal={noTotal} listCheked={listCheked} setListCheked={setListCheked} setCheckAll={setCheckAll} key={i} clientDataIndex={i} clientData={clientData} setCurrentData={setCurrentData} allThs={allThs} ths={ths} i={i} />
                        ))
                    )
                }
                {
                    (currentData != null && currentData.data.length-(noTotal ? 0 : 1) == 0) && (
                        <>
                            <tr className='text-xl text-gray-800 h-[80px]'>
                                <td>

                                </td>
                                <td colSpan={ths.length}>
                                    No result has been found
                                </td>
                                <td>

                                </td>
                            </tr>
                        </>
                    )
                }
                {
                    (noTotal != true && currentData != null && currentData.data.length-1 > 0) && (
                        <tr className="h-[52px] even:bg-gray-50 odd:bg-white">
                            <td className="">
                                
                            </td>
        
                            {
                                allThs.map((key0, i) => {
                                    return (
                                        ths.map((tz) => tz.display).includes(key0.display) && (
                                            <td key={i} className={`font-semibold text-gray-700 px-6 py-4 ${(key0 == 'first_name') && `hover:underline sticky left-0 ${currentData.data.length%2 == 0 ? 'bg-gray-50' : 'bg-white'} px-6 py-4 font-medium text-gray-900 whitespace-nowrap`} `}>
                                                {
                                                    key0.key == 'transaction_sum' && (
                                                        currentData.data[currentData.data.length-1].transaction_sum
                                                    )
                                                }
                                            </td>
                                        )
                                    )
                                }
                                )
                            }
                        </tr>
                    ) 
                }
            </tbody>
        </table>
        <TableBott noTotal={noTotal} isUsersPage={isLeadPage} setUploadForce={setUploadForce} listCheked={listCheked} setListCheked={setListCheked} setCurrentData={setCurrentData} setObjFilter={setObjFilter} handlerPage={handlerPage} objFilter={objFilter} currentData={currentData} />
    </div>
  )
}
