import React from 'react'
import { motion } from "framer-motion";

export default function LoadingPage() {
  return (
    <div className='absolute cursor-wait animate-pulse top-0 z-50 flex justify-center items-center bg-[rgba(243,244,246,0.58)] min-h-screen w-full text-3xl'>
        <motion.img 
        src='/images/loading.png'
        initial={{
          opacity: 0
        }}
        animate={{
          x: [-1000, 0, 0],
          opacity: [0, 1],
        }}
        transition={{ duration: 1.5 }}
        />
    </div>
  )
}
