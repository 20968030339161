import React, { useEffect, useState, useRef } from 'react'

export default function SelectPro({
    optionsData,
    choosedOption, setChoosedOption,
    randomId,
    onClick0,
    display,
    sty
}) {

    if (choosedOption == null) {
        choosedOption = {name: '', id: ''}
    }


    const [dropped, setDropped] = useState(false)
    const [titleBut, setTitleBut] = useState(choosedOption.name)
    const [optionsDataAfterSearch, setOptionsDataAfterSearch] = useState(optionsData)
    const [textSearch, setTextSearch] = useState('')
    const myElementRef = useRef();
    const [hei , setHei] = useState(250)
    const [toUp, setToUp] = useState(false)

    useEffect(() => {
        if (dropped) {
            const bodyHeight = document.body.offsetHeight;
            const ulElement = myElementRef.current.querySelector('ul')
            const sized = ulElement.getBoundingClientRect()
            setHei(sized.height)
            if (bodyHeight - (sized.y + sized.height + 36) < 0) {
                setToUp(true)
            }
        }
    }, [dropped])





    useEffect(() => {
        setOptionsDataAfterSearch(optionsData.filter(offi => offi.name.toLowerCase().includes(textSearch.toLowerCase())))
    }, [textSearch])

    useEffect(() => {
        setTitleBut(choosedOption.name)
    }, [choosedOption])

    useEffect(() => {
        setOptionsDataAfterSearch(optionsData)
    }, [optionsData])

  return (
    <div id={`drop-col-${randomId}`} ref={myElementRef} className='w-full' onClick={onClick0 != null ? onClick0 : () => {}}>
        <button type='button' onClick={() => setDropped(prevValue => !prevValue)} className={`inp !hover:bg-none flex flex-row items-center justify-between h-[38px] px-2 ${sty === null ? 'w-[160px]': sty} w-full`}>
            <span className='truncate'>{titleBut}</span>
            <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/></svg>
        </button>
        {
            dropped && (
                <DroppedList toUp={toUp} hei={hei} setDropped={setDropped} randomId={randomId} textSearch={textSearch} setTextSearch={setTextSearch} setChoosedOption={setChoosedOption} choosedOption={choosedOption} optionsDataAfterSearch={optionsDataAfterSearch} />
            )
        }

    </div>
  )
}





////////// DROP LIST //////////

function DroppedList({
    textSearch, setTextSearch,
    optionsDataAfterSearch,
    choosedOption, setChoosedOption,
    randomId,
    setDropped,
    hei, toUp
}) {
    useEffect(() => {
        const handleBodyClick = (e) => {
            var element = e.target
            var isInside = false;
            while (true) {
                if (`drop-col-${randomId}` == element.id) {
                    isInside = true
                    break
                }
                element = element.parentElement
                if (element == null) {
                    break
                }
            }
            if (!isInside) {
                setDropped(false)
            }
        };
        document.body.addEventListener('click', handleBodyClick);
        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, [])

    return (
        <div className='relative top-1'>
            <div style={{
                bottom: toUp && (hei + 45)
            }} className={`absolute w-[150px]`}>
                <div className='bg-white border border-b-0 p-2'>
                    <input value={textSearch} type='text' onChange={(e) => setTextSearch(e.target.value)} className='inp bg-white shadow-none' />
                </div>
                <div className='border z-30 scrollbar-thin w-full scrollbar-thumb-gray-900 scrollbar-transparent absolute h-auto overflow-y-auto bg-white overflow-hidden divide-y divide-gray-100'>
                    <ul className='text-sm text-gray-700 relative max-h-[200px]'>
                        {
                            optionsDataAfterSearch.map((optionObj, i) => {
                                return (
                                    <div 
                                    key={i}
                                    onClick={() => {setChoosedOption(optionObj); setDropped(false)}}
                                    className={`py-3 px-2 min-h-[40px] hover:bg-gray-50 ${choosedOption.id == optionObj.id && 'bg-gray-100'}`}
                                    >
                                        <label className=''>
                                            {optionObj.name}
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}