import React, { useState } from 'react'
import { isLogin, loading } from '../../lib/states';
import { post } from '../../lib/apiHandler'
import { useRecoilState } from 'recoil';
import { useAuth } from '../../lib/AuthContext';
import { useNavigate } from 'react-router-dom';

function Login() {
  //const [isLogged, setIsLogged] = useRecoilState(isLogin);
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [errText, setErrText] = useState('')
  const [isLoading, setIsLoading] = useRecoilState(loading)
  const { login } = useAuth();
  const navigate = useNavigate();
 
  const handleLog = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    setErrText('')
    const result = await post('/user/login', {
      email: email,
      password: pass
    })

    if (result.status == 200) {
      localStorage.removeItem('clientFilter')
      localStorage.removeItem('userFilter')
      localStorage.removeItem('columns-client')
      login(result.data.token)
      navigate('/');
    } else {
      setErrText(result.data.message)
      setIsLoading(false)
    }

  }

  return (
    <div className='bg-[rgba(255,112,67,0.1)] min-h-screen flex justify-center items-center'>
      <div className='card rounded-lg flex flex-col space-y-5 max-w-[500px] w-[95%] px-10 py-10'>
        <form className='' onSubmit={handleLog}>
          <h1 className='text-2xl'>Welcome aboard!</h1>
          <div className='flex flex-col space-y-4 text-gray-500'>
            <div className='flex flex-col space-y-1'>
              <span className='text-xs'>EMAIL</span>
              <input value={email} onChange={(e) => setEmail(e.target.value)} className='inp px-4 text-lg text-gray-700' type='email' required />
            </div>
            <div className='flex flex-col space-y-1'>
              <span className='text-xs'>PASSWORD</span>
              <input value={pass} onChange={(e) => setPass(e.target.value)} className='inp px-4 text-lg text-gray-700' type='password'required />
            </div>
          </div>
          <div className='flex flex-row justify-between items-center !mt-[30px]'>
            <span className='text-red-700'>
              {errText}
            </span>
            <button disabled={isLoading} type='submit' className={`but font-semibold text-lg h-[46px] w-[98px] disabled:bg-orange-400`}>Log in</button>
          </div>
        
        </form>
      </div>
    </div>
  )
}

Login.displayName = "login";
export default Login;