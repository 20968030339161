import { useEffect, useState } from "react"
import SelectProMulty from "../inps/SelectProMulty-new"
import country_two_iso from '../../lib/country_two_iso';

const SelectCountry = ({choosedCountry, setChoosedCountry}) => {
    //const [choosedCountry, setChoosedCountry] = useState([])

    const handleChoose = (userObj, isInc) => {
        if (!isInc) {
            setChoosedCountry(prevValue => [...prevValue, userObj])
        } else {
            setChoosedCountry(prevValue => prevValue.filter(prev => prev.id !== userObj.id))
        }
    }

    return (
        <>
            <SelectProMulty allBut={false} randomId={`th-country-user`} optionsData={country_two_iso} choosedOption={choosedCountry} setChoosedOption={handleChoose} titleKey={'countries'} />
        </>
    )
}

export default SelectCountry