import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const data5 = [
  {
    uv: 100,
    pv: 40,
    amt: 200,
    lor: 150,
  },
];


const Example = ({data0}) => {

  const data = 
  (data0 != null && Object.keys(data0).length != 0) 
  ? ([
      {
        'New': data0['New'],
        'Call again': data0['Call again'],
        'No answer': data0['No answer'],
        'Not Interested': data0['Not Interested'],
        'Deposit': data0['Deposit'],
        'In progress': data0['In progress'],
        'Meeting': data0['Meeting'],
        'Wrong Details': data0['Wrong Details']
      },
  ]) : ([
    {
      'New': 0,
      'Call again': 0,
      'No answer': 0,
      'Not Interested': 0,
      'Deposit': 0,
      'In progress': 0,
      'Meeting': 0,
      'Wrong Details': 0
    }
  ])

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        // margin={{
        //   top: 5,
        //   right: 30,
        //   left: 20,
        //   bottom: 5,
        // }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        {/* <YAxis /> */}
        <Tooltip />
        <Legend />
        <Bar dataKey="New" fill="#c4c2d6" />
        <Bar dataKey="In progress" fill="#6fd99f" />
        <Bar dataKey="Call again" fill="#797fde" />
        <Bar dataKey="No answer" fill="#ffc565" />
        <Bar dataKey="Not Interested" fill="#ff8f8f" />
        <Bar dataKey="Deposit" fill="#c1f013" />
        <Bar dataKey="Wrong Details" fill="#ff2f37" />
        <Bar dataKey="Meeting" fill="#74e9ff" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Example;
