import React, { useEffect } from 'react'
import { DateRangePicker } from 'react-date-range';
import { addDays, format } from 'date-fns';
import { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useRecoilState } from 'recoil';
import { toForce0 } from '../lib/states';


export default function DateRange({objFilter, setObjFilter}) {
    const [toForce, SetToForce] = useRecoilState(toForce0)

    const getState = () => {
        return [{
            startDate: new Date(objFilter.startDate),
            endDate: new Date(objFilter.endDate),
            key: 'selection'
        }]
    }
    const [state, setState] = useState(getState());

    const [drop, setDrop] = useState(false)
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

    const applyDates = () => {
        setObjFilter((prevValue) => {
            return {
                ...prevValue,
                startDate: format(state[0].startDate, 'yyyy-MM-dd'),
                endDate: format(state[0].endDate, 'yyyy-MM-dd')
            }
        })
        setDrop(false)
        SetToForce(prev => !prev)
    }

    useEffect(() => {
        const handleBodyClick = (e) => {
            var element = e.target
            var isInside = false;
            while (true) {
                if (`drop-dates` == element.id) {
                    isInside = true
                    break
                }
                element = element.parentElement
                if (element == null) {
                    break
                }
            }
            if (!isInside) {
                setDrop(false)
            }
            
        };
        document.body.addEventListener('click', handleBodyClick);
        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, [])

  return (
    <div id='drop-dates'>
        <button className='but-emp px-2 overflow-hidden' onClick={() => {setDrop(prevValue=> !prevValue); setState(getState())}}>
            {new Date(objFilter.startDate).toLocaleDateString(undefined, options)}
            {` - `}
            {new Date(objFilter.endDate).toLocaleDateString(undefined, options)}
        </button>
        {
            drop && (
                <div className='absolute card'>
                    <DateRangePicker
                        onChange={item => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={state}
                        direction="horizontal"
                        rangeColors={['#FF7043']}
                        color={'#FF7043'}
                    />
                    <div className='flex flex-row justify-end space-x-2 m-2'>
                        <button onClick={() => setDrop(false)} className='but-emp'>
                            Cancel
                        </button>
                        <button onClick={applyDates} className='but'>
                            Apply
                        </button>
                    </div>
                </div>
            )
        }
    </div>
  )
}
