import React, { useEffect } from 'react'

export default function Sel({opts, optsVal, val, setVal, ids}) {
  return (
    <select  className='inp' value={val} onChange={setVal} id={ids != null ? ids : ''}>
        {
            opts.map((item, i) => (
                <option key={i} value={optsVal[i]}>
                    {item}
                </option>
            ))
        }
    </select>
  )
}
