import {atom} from 'recoil';

export const isLogin = atom({
    key: 'isLogin',
    default: null,
});

export const loading = atom({
    key: 'loading',
    default: null,
});

export const notiList0 = atom({
    key: 'notiList0',
    default: [],
});

export const currentUser0 = atom({
    key: 'currentUser0',
    default: null,
});

export const officesList0 = atom({
    key: 'officesList0',
    default: null,
});

export const desksList0 = atom({
    key: 'desksList0',
    default: [],
});

export const usersList0 = atom({
    key: 'usersList0',
    default: null,
});

export const checkedLeads = atom({
    key: 'checkedLeads',
    default: [],
});

export const popWin0 = atom({
    key: 'popWin0',
    default: {
        disable: false,
        msg: '',
        func: () => {}
    },
});

export const popWinMsg = atom({
    key: 'popWinMsg',
    default: {
        disable: false,
        msg: '',
        func1: null,
        func2: null,
    },
});



export const lastCall0 = atom({
    key: 'lastCall0',
    default: null,
});



export const toForce0 = atom({
    key: 'toForce0',
    default: false,
});

