import { useRecoilState } from 'recoil';
import { loading, notiList0, currentUser0, officesList0, desksList0, usersList0 } from '../../lib/states';
import Menu from '../../components/parts/Menu';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { get } from '../../lib/apiHandler';
import objectToQueryParams from '../../lib/objToParams';
import Alert from '../../components/Alert';
import Pop from '../../components/parts/Pop';
import Pop2 from '../../components/parts/Pop2';
import LoadingPage from '../../components/parts/LoadingPage';


function Layout({children}) {
    //const router = useRouter()
    const router = useNavigate();
    const [isLoading, setIsLoading] = useRecoilState(loading)
    const [notiList, setNotiList] = useRecoilState(notiList0)
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const [officesList, setOfficesList] = useRecoilState(officesList0)
    const [desksList, setDesksList] = useRecoilState(desksList0)
    const [usersList, setUsersList] = useRecoilState(usersList0)

    const isAlive = async () => {
      const result = await get('/alive')
      if (result.data.status === false) {
        localStorage.removeItem('token')
        localStorage.removeItem('clientFilter')
        localStorage.removeItem('userFilter')
        localStorage.removeItem('columns-client')

        window.location.reload(true)
      }
    }

    useEffect(() => {
      isAlive()
    }, [])


    
    useEffect(() => {
      const timer = setTimeout(() => {
        setNotiList(prevValue => prevValue.slice(1))
      }, 5000);
      return () => clearTimeout(timer);
    }, [notiList])



    // useEffect(() => {
    //   const handleStart = (url) => (url !== router.asPath) && setIsLoading(true);
    //   const handleComplete = (url) => {
    //     if (url === router.asPath) {
    //       setIsLoading(false)
    //     } 
    //   };
    //   router.events.on('routeChangeStart', handleStart)
    //   router.events.on('routeChangeComplete', handleComplete)
    //   router.events.on('routeChangeError', handleComplete)
  
    //   return () => {
    //     router.events.off('routeChangeStart', handleStart)
    //     router.events.off('routeChangeComplete', handleComplete)
    //     router.events.off('routeChangeError', handleComplete)
    //   }
    // })

    useEffect(() => {
      router.isReady && setIsLoading(false)
    }, [router])


    useEffect(() => {
      if (isLoading) {
        setNotiList([])
      }
    }, [isLoading])


    useEffect(()  =>  {
      const result = async () => {
        const userData = await get('/user')
        setCurrentUser(userData.data)
        const officesListDate = await get(`/offices?${objectToQueryParams({all: true})}`)
        if (officesListDate.status == 200) {
        setOfficesList(officesListDate.data)

        var newListDesks = []
        for (var x in officesListDate.data) {
            newListDesks.push(...officesListDate.data[x].desks)
        }
        setDesksList(newListDesks)

        } else {
        setOfficesList([])
        }
        const usersData = await get('/users?all=true')
        if (usersData.status == 200) {
          setUsersList(usersData.data)
        }
        setIsLoading(false)
      }
      result();
    }, [])

    if (currentUser === null || officesList === null || usersList === null) return <LoadingPage />
    return (
        <div className={`flex flex-col sm:flex-row ${ isLoading && 'ever'}`}>
            <div className='hidden sm:flex'>
                <Menu />
            </div>
            <div className={`${ isLoading && 'animate-pulse'} relative min-h-[calc(100vh-70px)] bg-gray-50 flex justify-center sm:min-h-screen sm:p-5 p-2 w-full sm:max-w-[calc(100vw-70px)]`}>
                <div className="max-w-[1750px] w-full">
                    {children}
                </div>
                <Alert />
                <Pop />
                <Pop2 />
            </div>
            <div className='sm:hidden sticky bottom-0'>
                <Menu />
            </div>
        </div>
    );
  }
  
  export default Layout;
  