import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { FiMail } from 'react-icons/fi';
import { LuPhone } from 'react-icons/lu';
import { RiDeleteBin5Line } from 'react-icons/ri';

import isoToCountry from '../../lib/isoToCountry'

import { del, get, post, put } from '../../lib/apiHandler';

import Skeleton from 'react-loading-skeleton'

import { useRecoilState } from 'recoil';
import { loading, notiList0, popWin0, currentUser0, ext0 } from '../../lib/states';
import TableTransClient from '../../components/tables/Table-trans';

import TablePro from '../../components/tables/TablePro';
import NoteSection from '../../components/NoteSection';
import langIsoToName from '../../lib/langIsoToName';
import SelectLang from '../../components/inps2/SelectLang';
import Forword from '../../components/Forword';
//import TableProHandler from '../../components/tables/TableProHandler';



export default function ClientPage() {
    // GENERAL
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const [notiList, setNotiList] = useRecoilState(notiList0)
    const {clientId} = useParams();
    
    const [currentCli, setCurrentCli] = useState(null)
    const [isLoading, setIsLoading] = useRecoilState(loading)

    const ths = ['action date', 'event', 'changed filed', 'old value', 'new value', 'user action']
    const ths_trans = ['date', 'amount', 'user']

    useEffect(() => {
        const func = async () => {
            const result = await get(`/lead/${clientId}`)
            if (result.status == 200) {
                setCurrentCli(result.data)
            } else {
                setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
            }
        }
        func()
    }, [clientId])




    const [updateLog, setUpdateLog] = useState(true)

    const [popWin, setPopWin] = useRecoilState(popWin0)
    const removeLog = async (logId) => {

        const newFunc = async () => {
            const result = await del(`/log/${logId}`)
            if (result.status == 200) {
                setUpdateLog(prevValue => !prevValue)
                setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: true}])
            } else {
                setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])
            }
        }

        setPopWin({
            disable: true,
            msg: 'Are you sure you want to delete this Log?',
            func: newFunc
        })
    }
    const makeCall = async  () => {
        const result = await post(`/lead/${clientId}/call`, {})
        if (result.ok) {
            setNotiList(prevValue => [...prevValue, {text: 'Success', isSucc: true}])
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}])

        }
    }
    const router = useNavigate()
    const handleRemoveLead = async () => {
        setIsLoading(true)
        const newFunc = async () => {
            const result = await post('/leads/delete', {
                ids: [clientId]
            })
            if (result.status == 200) {
                setNotiList(prevValue => [
                  ...prevValue,
                  {text: result.data.message, isSucc: true} //'Lead successfully deleted'
                ])
                router('/clients')
              } else {
                setNotiList(prevValue => [
                  ...prevValue,
                  {text: result.data.message, isSucc: false}
                ])
            }
        }
        setPopWin({
            disable: true,
            msg: 'Are you sure you want to delete this lead?',
            func: newFunc
        })
        setIsLoading(false)
    }






  return (
    <>
    <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
        <div className='col-span-2 space-y-5'>
            <div className='card p-0 relative '>
                <div className='border-b w-full flex flex-row items-center'>
                    <div className='bg-basic m-5 rounded-full w-20 h-20 flex justify-center items-center text-white text-2xl font-semibold'>

                        {
                            currentCli != null && (
                                <>{currentCli.first_name.slice(0,1)}{currentCli.last_name.slice(0,1)}</>
                            )
                        }
                        
                    </div>
                    <div className='flex flex-col text-md'>
                        {
                            currentCli != null
                            ? (
                                <span className='text-2xl font-semibold'>
                                    {currentCli.first_name} {currentCli.last_name}
                                </span>
                            ) : (
                                <span className='w-[100px] h-10'>
                                    <Skeleton />
                                </span>
                            )
                        }

                        <div className='flex flex-col justify-start items-start'>
                            <div className='flex flex-row items-center justify-center text-gray-700 space-x-2'>
                                <FiMail className='relative' />
                                {
                                    currentCli != null
                                    ? (
                                        <span>{currentCli.email}</span>
                                    ) : (
                                        <span className='w-20'>
                                            <Skeleton />
                                        </span>
                                    )
                                }
                            </div>
                            
                            
                            <div className='flex flex-row items-center justify-center text-gray-700 space-x-2'>
                                <LuPhone className='relative bottom-[1px]' />
                                {
                                    currentCli != null
                                    ? (
                                        <span>{currentCli.phone}</span>
                                    ) : (
                                        <span className='w-20'>
                                            <Skeleton />
                                        </span>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='absolute top-0 right-0 flex flex-row p-4 space-x-2'>
                        {
                            currentCli != null ? (
                                <>
                                    {
                                        currentUser.access_level == 4 && (
                                            <Cir icon={<RiDeleteBin5Line />} isRed={true} func={handleRemoveLead} />
                                        )
                                    }
                                    {/* <Cir icon={<LuPhone />} func={makeCall} /> */}
                                </>
                            ) : (
                                <span className='h-10 w-10'>
                                    <Skeleton circle={true} className='h-10 w-10' />
                                </span>
                            )
                        }
                    </div>
                </div>
                {
                    currentCli != null
                    ? (
                    <>
                        <div className='flex flex-row [&>div]:w-1/4 [&>div]:border'>
                            <Rib up="agent" bott={currentCli.user != null ? currentCli.user.name : 'emp'} />
                            <Rib up="brand" bott={currentCli.brand} />
                            <Rib up="register date" bott="12/08/2023" />
                            <Rib up="total transactions" bott={currentCli.total_transaction_sum} />
                        </div>
                        <div className='flex flex-row [&>div]:w-1/5 [&>div]:border'>
                            <Rib up="country" bott={isoToCountry[currentCli.country]} />
                            <Rib up="file_name" bott={currentCli.file_name} />
                            
                            {/* isoToCountry[clientData[key0.key]] */}
                            <Rib up="status" bott={currentCli.status} />
                            <Rib up="office" bott={currentCli.office != null ? currentCli.office.name : 'emp'} />
                            <Rib up="desk" bott={currentCli.desk != null ? currentCli.desk.name : 'emp'} />
                        </div>
                        <div className='flex flex-row [&>div]:w-1/5 [&>div]:border'>
                            <Rib up="net_deposit" bott={currentCli.net_deposit} />
                            <Rib up="ftd date" bott={currentCli.ftd_date} />
                            <Rib up="created at" bott={currentCli.created_at} />
                            <Rib up="last_time_deposit" bott={currentCli.last_time_deposit} />
                            <Rib up="language" bott={
                                <SelectLang clientId={clientId} defaultLang={currentCli.lang} />
                            } />
                        </div>
                    </>
                    ) : (
                        [1,2,3].map(w => (
                            <div key={w} className='flex flex-row [&>div]:w-1/4 [&>div]:border'>
                                {
                                    [1,2,3,4].map(z => (
                                        <div key={z} className='flex flex-col p-4'>
                                            <span className=''><Skeleton /></span>
                                            <span className=''><Skeleton /></span>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    )
                }
            </div>
            <div className='flex flex-row card justify-between [&>div]:border [&>div]:w-1/3 [&>div]:p-4'>
                <div className='flex flex-col p-4 overflow-x-hidden'>
                    <span className='uppercase text-sm text-gray-600'>amount lost</span>
                    <span className='text-lg font-semibold'>{currentCli?.amount_lost}</span>
                </div>
                <div className='flex flex-col p-4 overflow-x-hidden'>
                    <span className='uppercase text-sm text-gray-600'>method used</span>
                    <span className='text-lg font-semibold'>{currentCli?.method_used}</span>
                </div>
                <div className='flex flex-col p-4 overflow-x-hidden'>
                    <span className='uppercase text-sm text-gray-600'>domain</span>
                    <span className='text-lg font-semibold'>{currentCli?.domain}</span>
                </div>
            </div>
            <div className='flex flex-col space-y-2'>
                <div className='flex flex-row justify-between items-center h-10'>
                    <span className='text-2xl font-semibold'>
                        Initial comment
                    </span>
                </div>
                <div className='card p-4'>
                    {currentCli?.init_comment}
                </div>
            </div>
            <NoteSection ids={clientId} />
        </div>
        <div className='flex flex-col space-y-4'>
            <div className='flex flex-col space-y-2'>
                <TableTransClient clientId={clientId} title="Transaction record" ths={ths_trans} ids={currentCli != null ? currentCli.id : ''}  />
            </div>


            {/* <Forword agent={currentCli?.user} /> */}



            <TablePro handleUpdate={updateLog} deletability={removeLog} isSRCready={currentCli != null ? true : false} title="Logs record" src={`/lead/${currentCli != null && currentCli.id}/logs`} ths={["created_at", "description", 'user']} />
        </div>
    </div>
    </>
  )
}


function Rib({up, bott}) {
    return (
        <div className='flex flex-col p-4 overflow-x-hidden'>
            <span className='uppercase text-sm text-gray-600'>{up}</span>
            <span className='text-lg font-semibold'>{bott}</span>
        </div>
    )
}

function Cir({icon, func, isRed}) {
  return (
    <div onClick={func} className={`border rounded-full w-10 h-10 flex items-center justify-center text-gray-600 hover:bg-basic hover:text-white cursor-pointer ${isRed && 'hover:bg-danger text-danger'}`}>
        {icon}
    </div>
  )
}

function SkeletonTab({ths}) {
    return (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 the-tab">
            <thead className="sticky top-0 z-10 text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                <tr className='bg-gray-50'>
                    {
                        ths.map((title, i) => (
                            <th key={i} scope="col" className={`px-6 py-3 bg-gray-50`}>
                                {title}
                            </th>
                        ))
                    }
                </tr>
            </thead>
            <tbody>
                
            </tbody>
        </table>
    )
}
