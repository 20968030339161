//import { useRouter } from 'next/router'
import { useNavigate } from 'react-router-dom';
import React, {useEffect, useState, useRef } from 'react'
import { AiOutlinePlus, AiFillCalendar, AiOutlineFileExcel } from 'react-icons/ai';
import DropList2 from '../inps/DropList2';
import BubbleFilters from '../BubbleFilters';
import { currentUser0, notiList0, popWin0, loading } from '../../lib/states';
import { useRecoilState } from 'recoil';
import DateRange from '../DateRange';
import { CSVLink, CSVDownload } from "react-csv";
import { RiDeleteBin5Line } from 'react-icons/ri';
import { post, put } from '../../lib/apiHandler';
import { SlRefresh } from "react-icons/sl";


export default function ControlesTab({
    objFilter, setObjFilter, ths, setThs, allThs, setUploadForce, setChoosedAfterRem,
    listCheked, currentData
}) {
    //const router = useRouter();
    const router = useNavigate();
    const filter = JSON.parse(objFilter.filter)
    const filterKeys = Object.keys(filter)
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const myObjectRef = useRef(null);
    const [notiList, setNotiList] = useRecoilState(notiList0)
    const [isLoading, setIsLoading] = useRecoilState(loading)

    const handleRem = (title) => { /// ??
        setObjFilter(prevValue => {
            var new_filter = JSON.parse(prevValue.filter)
            delete new_filter[title];
            return {
                ...prevValue,
                filter: JSON.stringify({
                    ...new_filter
                })
            }
        })
    }

    const handleExportFile = async () => {
        if (listCheked.length > 0) {
            var listKeys = []
            allThs.map(thA => (
                ths.map(thB => {
                    if (thA.display == thB.display) {
                        listKeys.push(thA.display)
                    }
                })
            ))
            
            var endData = [listKeys]
            for (var z in listCheked) {
                var line = []
                for (var t in listKeys) {
                    if (currentData.data[listCheked[z]][listKeys[t]] == null) {
                        line.push('')
                    } else if (typeof currentData.data[listCheked[z]][listKeys[t]] == typeof {}) {
                        line.push(currentData.data[listCheked[z]][listKeys[t]].name)
                    } else {
                        line.push(currentData.data[listCheked[z]][listKeys[t]])
                    }
                }
                endData.push(line)
            }
            setDataExp(endData)
            await delay(1500);
            myObjectRef.current.click()
        } else {
            setNotiList(prevValue => [...prevValue, {text: 'Please mark relevant lines', isSucc: false}])
        }
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));


    const [DataExp, setDataExp] = useState([])

    const [popWin, setPopWin] = useRecoilState(popWin0)
    const handleRemoveLeads = async () => {
        setIsLoading(true)
        const newFunc = async () => {
            var leadsToRemove = []
            for (var x in listCheked) {
                leadsToRemove.push(currentData.data[listCheked[x]].id)
            }
            const result = await post('/leads/delete', {
                ids: leadsToRemove
            })
            if (result.status == 200) {
                setNotiList(prevValue => [
                  ...prevValue,
                  {text: result.data.message, isSucc: true} //'Leads successfully deleted'
                ])
                setUploadForce(prevValue => !prevValue)
              } else {
                setNotiList(prevValue => [
                  ...prevValue,
                  {text: result.data.message, isSucc: false}
                ])
            }
        }
        setPopWin({
            disable: true,
            msg: 'Are you sure you want to delete the marked leads?',
            func: newFunc
        })
        setIsLoading(false)
    }


    ////////////// EXT //////////////
    const [extState, setExtState] = useState(currentUser.ext !== null ? currentUser.ext : '')

    const updateExt = async () => {
        
        if (extState === currentUser.ext || extState === '' && currentUser.ext === null) return;
        const result = await put(`/users/${currentUser.id}`, {
            ext: extState === '' ? null : extState
        })
        if (result.ok) {
            setCurrentUser(result.data)
            setNotiList(prevValue => [
                ...prevValue,
                {text: 'EXT successfully updated', isSucc: true}
            ])
        } else {
            setNotiList(prevValue => [
                ...prevValue,
                {text: 'User update failed', isSucc: false}
            ])
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            updateExt()
        }, 1500);
    
        return () => {
          clearTimeout(timer);
        };
    }, [extState]);

    return (
        <div className="z-30 flex items-center justify-between flex-row overflow-x-auto overflow-y-hidden">
            <div className='flex flex-row space-x-4 items-center'>
                <DateRange objFilter={objFilter} setObjFilter={setObjFilter} />
                <div>
                    <input value={currentUser.extension !== null ? currentUser.extension : ''} className='inp w-[70px] text-gray-500 text-center shadow-none' disabled={true} />
                </div>
                <div>
                    <input value={extState} onChange={(e) => {setExtState(e.target.value)}} placeholder='EXT' className='inp w-[70px] text-gray-500 text-center shadow-none' />
                </div>
                <div>
                    <BubbleFilters allThs={allThs} objFilter={objFilter} setObjFilter={setObjFilter} setChoosedAfterRem={setChoosedAfterRem} />
                </div>
            </div>
            <div className="flex justify-end flex-shrink-0 w-auto flex-row items-center space-x-3">
                <button className='but px-2' onClick={() => setUploadForce(prevValue => !prevValue)}>
                    <SlRefresh className='text-lg' />
                </button>
                {
                    currentUser.access_level == 4 && (
                        <>
                            {
                                listCheked.length > 0 && (
                                    <button className='but-red  px-2' onClick={handleRemoveLeads}>
                                        <RiDeleteBin5Line className='text-lg' />
                                    </button>
                                )
                            }
                            <button className='but-emp px-2' onClick={handleExportFile}>
                                <AiOutlineFileExcel className='text-lg' />
                            </button>

                            <CSVLink data={DataExp} className='hidden' filename='Leads information'>
                                <div ref={myObjectRef}>
                                </div> 
                            </CSVLink>
                        </>
                    )
                }
                <DropList2 ths={ths} setThs={setThs} allThs={allThs} title={'columns'} id="dsf" />
                {
                    currentUser.access_level == 4 && (
                        <button type="button" onClick={() => router('/clients/import')} className="space-x-1 text-white bg-basic hover:bg-basic_hov flex flex-row items-center justify-center px-4 py-2 text-sm font-medium rounded-lg focus:ring-4 focus:ring-violet-300 focus:outline-none">
                            <span>
                                Import
                            </span>
                            <AiOutlinePlus />
                        </button>
                    )
                }
            </div>
        </div>

  )
}
