export default function range(start, end, step = 1) {
    let result = [];
    
    if (step === 0) {
        throw new Error("Step cannot be zero");
    }
    
    if (step > 0) {
        for (let i = start; i < end; i += step) {
            result.push(i);
        }
    } else {
        for (let i = start; i > end; i += step) {
            result.push(i);
        }
    }
    
    return result;
}