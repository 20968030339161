import { get } from '../../lib/apiHandler'
import React, { useEffect, useState } from 'react'
import { notiList0 } from '../../lib/states';
import { useRecoilState } from 'recoil';
import Skeleton from 'react-loading-skeleton'
import range from '../../lib/range';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';

export default function TablePro({src, ths, title, isSRCready, coteret, deletability, handleUpdate}) {
    const [notiList, setNotiList] = useRecoilState(notiList0)

    const [data, setData] = useState(null)
    const [pageIndex, setPageIndex] = useState(1)
    const [pageCount, setPageCount] = useState(1)

    const getData = async () => {
        setData(null)
        const result = await get(`${src}?page=${pageIndex}`)
        if (result.status == 200) {
            if (result.data['logs'] != null){ 
                setPageCount(result.data['logs'].last_page)
                setData(result.data['logs'].data)
            } else {
                setPageCount(result.data.last_page)
                setData(result.data.data)
            }
        } else {
            setNotiList(prevValue => [...prevValue, {text: result.data.message, isSucc: false}]) //"Something went wrong"
            setData([])
        }
    }

    useEffect(() => {
        if (isSRCready != false) {
            getData()
        }
    }, [pageIndex, isSRCready, handleUpdate])


    const createRange = () => {
        var range = []
        for (var i=1; i<=pageCount; i++) {
            if (i === 1) range.push(i);
            else if (i === pageCount) range.push(i);
            else if (i === pageIndex) range.push(i);
            else if (i === pageIndex-1) range.push(i);
            else if (i === pageIndex+1) range.push(i);
            else range.push(true);
        }
        var newRan = range.filter((num, i) => {
            if (typeof num !== typeof true) return num;
            if (typeof range[i-1] !== typeof true) {
                return num
            }
        })

        var fullRan = []
        for (var i=1; i<=pageCount; i++) {
            fullRan.push(i);
        }
        if (newRan.length === range.length) return fullRan;
        return newRan;
    }
    



  return (
    <div className='flex flex-col space-y-2'>
        {
            coteret !== false && (
                <div className='space-x-2 flex flex-row items-center justify-between'>
                    <span className='text-2xl font-semibold'>
                        {title}
                    </span>
                </div>
            )
        }
        <div className='card  max-h-[350px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-900 scrollbar-transparen'>
            <table className='w-full'>
                <thead className="text-left sticky top-0 z-10 text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                    <tr className='bg-gray-50'>
                        {
                            ths.map((th, i) => (
                                <th key={i} scope="col" className={`px-4 py-3 bg-gray-50`}>
                                    {th}
                                </th>
                            ))
                        }
                        {
                            deletability != null && (
                                <th></th>
                            )
                        }
                    </tr>
                </thead>
                <tbody className='text-gray-500'>
                    {
                        data != null ? (
                            data.map((line, z) => (
                                <tr key={z} className='even:bg-gray-50 odd:bg-white'>
                                    {
                                        ths.map((th, i) => (
                                            <td key={i} className='px-4 py-4'>
                                                {
                                                    line[th] != null && (
                                                        typeof line[th] != typeof {} 
                                                        ? line[th] : (
                                                                line[th].name != null 
                                                                ? line[th].name
                                                                : <Link to={`/clients/${line[th].id}`}>{line[th].first_name}</Link>
                                                            )
                                                    )
                                                }
                                            </td>
                                        ))
                                    }
                                    {
                                        deletability != null && (
                                            <td>
                                                <button onClick={() => deletability(line.id)} className='hover:text-red-600 rounded-full p-2 mr-[2px]'><RiDeleteBin5Line className='text-lg' /></button>
                                            </td>
                                        )
                                    }
                                </tr>
                            ))
                        ) : (
                            [1,2].map((line, z) =>(
                                <tr key={z} className='even:bg-gray-50 odd:bg-white'>
                                    {
                                        ths.map((th, i) => (
                                            <td  key={i} className='px-4 py-4'>
                                                <Skeleton />
                                            </td>
                                        ))
                                    }
                                </tr>
                            ))
                        )
                    }
                </tbody>
            </table>

            <nav className='flex justify-end p-2 sticky bottom-0 left-0 bg-gray-50'>
                <div className="flex flex-row text-sm [&>span]:cursor-pointer list-none">
                    {/* {
                        pageIndex > 1
                        && <span onClick={() => setPageIndex(prevValue => prevValue - 1)} className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 ">Previous</span>            
                    } */}

                    {
                        pageIndex > 1 && 
                        <li onClick={() => setPageIndex(prevValue => prevValue - 1)}>
                            <span className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                            </span>
                        </li>
                    }
                    {/* {
                        range(0, pageCount).map((item, i) => (
                            i+1 == pageIndex
                            ?   (
                                <span key={i} aria-current="page" className="flex !cursor-default items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 ">{i+1}</span>
                            ) : (
                                <span key={i} onClick={() => setPageIndex(i+1)} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">{i + 1}</span>
                            )
                        ))
                    } */}


                    {
                        createRange().map((key, i) => (
                            <li key={i} onClick={() => {setPageIndex(key)}}>
                                <span style={{
                                    backgroundColor: pageIndex === key ? '#eff6ff' : 'white'
                                }} className={`${pageIndex !== key && 'cursor-pointer'} flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                                >
                                    {
                                        typeof key === typeof 1 ? (
                                            key
                                        ) : (
                                            "..."
                                        )
                                    }
                                </span>
                            </li>
                        ))
                    }


                    {/*
                    {
                        pageCount > pageIndex
                        && <span onClick={() => setPageIndex(prevValue => prevValue + 1)} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 ">Next</span>
                    } */}

                    {
                        pageCount > pageIndex && (
                            <li onClick={() => setPageIndex(prevValue => prevValue + 1)}>
                                <span className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                </span>
                            </li>
                        )
                    }
                </div>
            </nav>
        </div>
    </div>
  )
}
