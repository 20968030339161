import range from '../../lib/range'
import React, { useEffect } from 'react'
import { toForce0 } from '../../lib/states'
import { useRecoilState } from 'recoil'

export default function TabPageIndex({objFilter, setObjFilter, currentData, setCurrentData}) {
    const [toForce, SetToForce] = useRecoilState(toForce0)
    

    const pageMoving = (index) => {
        setCurrentData(null)
        setObjFilter(prevValue => {
            return {
                ...prevValue,
                page: index
            }
        })
        SetToForce(prev => !prev)
    }
    // const createRange = () => {
    //     var range = []
    //     for (var i=1; i<=currentData.last_page; i++) {
    //         range.push(i)
    //     }
    //     return range;
    // }


    const createRange = () => {
        var range = []
        for (var i=1; i<=currentData.last_page; i++) {
            if (i === 1) range.push(i);
            else if (i === currentData.last_page) range.push(i);
            else if (i === currentData.current_page) range.push(i);
            else if (i === currentData.current_page-1) range.push(i);
            else if (i === currentData.current_page+1) range.push(i);
            else range.push(true);
        }
        var newRan = range.filter((num, i) => {
            if (typeof num !== typeof true) return num;
            if (typeof range[i-1] !== typeof true) {
                return num
            }
        })

        var fullRan = []
        for (var i=1; i<=currentData.last_page; i++) {
            fullRan.push(i);
        }
        if (newRan.length === range.length) return fullRan;
        return newRan;
    }
  return (
    <nav aria-label="Page navigation example ">
        <ul className="inline-flex items-stretch -space-x-px">
            {
                currentData.current_page !== 1 && 
                <li onClick={() => pageMoving(currentData.current_page-1)}>
                    <span className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                    </span>
                </li>
            }

            {
                createRange().map((key, i) => {
                    const isDots = typeof key !== typeof 1
                    return (
                    <li key={i} onClick={isDots ? () => {} : () => pageMoving(key)}>
                        <span style={{
                            backgroundColor: currentData.current_page === key ? '#eff6ff' : 'white'
                        }} className={`${currentData.current_page !== key && !isDots && 'cursor-pointer'} ${isDots && 'cursor-default'} flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                        >
                            {
                                isDots ? (
                                    "..."
                                    ) : (
                                    key
                                )
                            }
                        </span>
                    </li>
                )})
            }

            {
                currentData.current_page !== currentData.last_page && (
                    <li onClick={() => pageMoving(currentData.current_page+1)}>
                        <span className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                            </svg>
                        </span>
                    </li>
                )
            }
            
        </ul>
    </nav>
  )
}
