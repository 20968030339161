import { useNavigate } from 'react-router-dom';

import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { AiOutlinePlus, AiOutlineClose } from 'react-icons/ai';
import { ImEnter } from 'react-icons/im'
import Epie from '../../components/charts/Epie';
import { get, post } from '../../lib/apiHandler';

import { useRecoilState } from 'recoil';
import { officesList0, notiList0, currentUser0, loading } from '../../lib/states';

import { convertValuesToNumber, updateOfficeList } from '../../lib/funcs'



export default function Teams() {
  const router = useNavigate();
  const [addOffice, setAddOffice] = useState(false)
  const [text, setText] = useState('')
  const [apiText, setApiText] = useState('')
  const [officesList, setOfficesList] = useRecoilState(officesList0)
  const [notiList, setNotiList] = useRecoilState(notiList0)
  const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
  const [isLoading, setIsLoading] = useRecoilState(loading)


  useEffect(() => {
    const func = async () => [
      await updateOfficeList(setOfficesList)
    ]
    func();
  }, [])


  const createOffice = async (e) => {
    e.preventDefault();
    if (text !== 'null') {
      setIsLoading(true)
      const result = await post('/office', {
        name: text,
        click2call: apiText
      })
      //console.log(result)
      if (result.status == 201) {
        await updateOfficeList(setOfficesList)
        setAddOffice(false)
        setNotiList(prevValue => [
          ...prevValue,
          {text: result.data.message, isSucc: true} //"Office successfully created"
        ])
      } else {
        setNotiList(prevValue => [
          ...prevValue,
          {text: result.data.message, isSucc: false}
        ])
      }
      setIsLoading(false)
    } else {
      setNotiList(prevValue => [
        ...prevValue,
        {text: 'Please choose another name', isSucc: false}
      ])
    }

  }



  if (officesList != null && currentUser.access_level < 4 && officesList.length == 1) {
    router(`/offices/${officesList[0].id}`)
  } else {
    
      return (
        <div className='flex justify-center'>
          <div className='flex flex-col space-y-4 h-min w-full '>
            <div className='flex flex-row justify-between items-center'>
              <div className='space-x-2 flex flex-row items-center'>
                  <span className='text-2xl font-semibold'>
                      Offices
                  </span>
                  <span className='bg-gray-200 px-4 py-[6px] rounded-md'>
                      {officesList != null ? officesList.length : 0}
                  </span>
              </div>
              {
                (!addOffice && currentUser.access_level == 4) && (
                <button 
                  onClick={() => setAddOffice(true)} 
                  className='text-basic hover:text-white px-3 py-2 hover:bg-basic rounded-lg font-semibold flex flex-row justify-center items-center space-x-1'
                  disabled={false}
                  >
                    <AiOutlinePlus className='relative top-[1px]'/>
                    <span>Add office</span>
                  </button>
                )
              }
            </div>
    
            {
              (addOffice &&  currentUser.access_level == 4) && (
                <form className='card flex flex-row p-4 justify-between' onSubmit={createOffice}>
                    <div className='flex flex-row space-x-2'>
                      <input value={text} onChange={(e) => setText(e.target.value)} placeholder="Office's name" className='inp max-w-[300px] px-3 text-lg text-gray-700' />
                      <input value={apiText} onChange={(e) => setApiText(e.target.value)} placeholder="url click2call" className='inp max-w-[300px] px-3 text-lg text-gray-700' />
                    </div>
                    <div className='flex flex-row justify-end'>
                        <button  onClick={() => setAddOffice(false)}  className="flex items-center hover:underline justify-center px-4 py-2 text-sm font-medium text-basic w-auto focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                            Cancel
                        </button>
                        <button disabled={isLoading} type="sumbit" className="disabled:bg-orange-400 space-x-1 text-white bg-basic hover:bg-basic_hov flex flex-row items-center justify-center px-4 py-2 text-sm font-medium rounded-lg focus:ring-4 focus:ring-violet-300 focus:outline-none">
                          <span>
                              Create office
                          </span>
                        </button>
                    </div>
                </form>   
              )
            }
    
            <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 text-gray-700  overflow-y-auto scrollbar-thin scrollbar-thumb-gray-900 scrollbar-transparen`}>
              {
                  officesList != null 
                  ? officesList.map((item, i) => (
                    <div key={i} className='card p-0 relative'>
                      <div className='border-b w-full flex flex-col items-center'>
                          <div className='py-7 flex space-y-2 flex-col justify-center items-center'>
                            <Epie data0={convertValuesToNumber(item.lead_status_counts)} />
                            <button onClick={() => router(`/offices/${item.id}`)} className='but-emp font-semibold text-lg px-4 py-1 flex flex-row items-center justify-center space-x-2'>
                              <span>
                                {item.name}
                              </span>
                              <ImEnter className='relative top-[2px]' />
                            </button>
                          </div>
                          <div className='absolute top-0 right-0 flex flex-row p-4 space-x-2'>
                              <Cir icon={<HiOutlineDotsHorizontal />} />
                          </div>
                      </div>
                      <div className='flex flex-row [&>div]:w-1/2 [&>div]:border text-center'>
                          <Rib up="agents" bott={item.agents_count} />
                          <Rib up="managers" bott={item.managers_count} />
                      </div>
                      <div className='flex flex-row [&>div]:w-1/2 [&>div]:border text-center'>
                          <Rib up="leads" bott={item.leads_count} />
                          <Rib up="desks" bott={item.desks.length} />
                      </div>
                    </div> 
                  ))
                  : (
                    [1,2,3,4].map((item, i) => (
                      <div key={i} className='card p-0 relative'>
                        <div className='border-b w-full flex flex-col items-center'>
                            <div className='py-7 flex space-y-2 flex-col justify-center items-center mt-7'>
                              <Skeleton
                                  circle
                                  height="164px"
                                  width="164px"
                              />
                              <h1 className='w-full'><Skeleton /></h1>
                            </div>
                            <div className='absolute top-0 right-0 flex flex-row p-4 space-x-2'>
                              <div className='border rounded-full w-10 h-10 flex items-center justify-center'>
                                <Skeleton width='16px' />
                              </div>
                            </div>
                        </div>
                        <div className='flex flex-row [&>div]:w-1/2 [&>div]:border'>
                            <Rib up="agents" bott={null} />
                            <Rib up="managers" bott={null} />
                        </div>
                        <div className='flex flex-row [&>div]:w-1/2 [&>div]:border'>
                            <Rib up="leads" bott={null} />
                            <Rib up="deposit sum" bott={null} />
                        </div>
                      </div> 
                    ))
                  )
              }
            </div>
          </div>
        </div>
      )

  }



}

function Rib({up, bott}) {


  if (bott == null) {
    return (
      <div className='flex flex-col p-4'>
          <span className='uppercase text-sm text-gray-600'>{up}</span>
          <span className='w-full mt-[5px]'><Skeleton /></span>
      </div>
    )
  }
  return (
      <div className='flex flex-col p-4'>
          <span className='uppercase text-sm text-gray-600'>{up}</span>
          <span className='text-lg font-semibold'>{bott}</span>
      </div>
  )
}

function Cir({icon}) {
  return (
    <div className='border rounded-full w-10 h-10 flex items-center justify-center text-gray-600 hover:bg-basic hover:text-white cursor-pointer'>
        {icon}
    </div>
  )
}